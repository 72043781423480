import { put, takeLatest } from 'redux-saga/effects';
import * as walletActions from '../../wallet/walletActions';
import * as walletClientApi from "../../wallet/walletClientApi";
import { getEmtData } from '../../accountData/fetchClientApi';
import * as emtActions from './emtActions';
import { showErrorAlert, showProcessAlert, showSuccessAlert } from "../../alerts/showAlert";

export const getEmtWatches = [
  getEmtStakeWatch,
  getEmtUnstakeWatch
];


export function* getEmtStakeWatch() {
  yield takeLatest(emtActions.getEmtStakeRoutine.TRIGGER, stakeEmt);
}
export function* getEmtUnstakeWatch() {
  yield takeLatest(emtActions.getEmtUnstakeRoutine.TRIGGER, unstakeEfx);
}

// Stake EMT Action
function* stakeEmt({ payload }) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'emanateoneos',
    method: 'grow',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getEmtData({ account_name: payload.id.owner });
    if (getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(emtActions.getEmtStakeRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(emtActions.getEmtStakeRoutine.failure(serverResponse));
  }
}

function* unstakeEfx({ payload }) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'emanateoneos',
    method: 'ungrow',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getEmtData({ account_name: payload.id.owner });
    if (getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(emtActions.getEmtUnstakeRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(emtActions.getEmtUnstakeRoutine.failure(serverResponse));
  }
}