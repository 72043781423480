/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Input, Header} from 'semantic-ui-react';
import * as walletActions from '../../../../wallet/walletActions';

class UnLend extends Component {
  constructor() {
    super();
    this.state = {
      rexSellAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { acctName, rexPrice, sellRex, rexBal } = this.props;
    const { rexSellAmount } = this.state;
    const rexDefault = '0.0000';

    return (
      <div className="st-pannel-inner st-card__lend">
        <div className="row">
          <div className="col col-md-6 text-center">
            <p className="st-card__bold ">REX TO SELL</p>
            <input
              type="number"
              min="0"
              className="st-input st-input--number"
              id="rexSellAmount"
              placeholder={rexDefault}
              value={rexSellAmount}
              onChange={this.onChangeInput}
              autoComplete="off"
            />
            <p className="st-card__bold font-s">{'AVAILABLE REX: ' + rexBal}</p>
          </div>

          <div className="col col-md-6 text-center">
            <p className="st-card__bold">EOS TO UNLEND</p>
            <p className="st-card__number st-card__number--purple">{rexSellAmount ? (rexSellAmount * rexPrice).toFixed(4) : '0.0000'}</p>
          </div>
        </div>
        <button
          type="button"
          className="st-button st-button--purple st-button--large"
          disabled={!acctName}
          onClick={() => {
            sellRex({
              acctName,
              data: {
                from: acctName,
                rex: parseFloat(rexSellAmount).toFixed(4) + ' REX',
              }
            });
          }}> SELL REX
        </button>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'rex_balance'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);
    const rexPrice = state.prices.getIn(['prices', 'data', 'rex', 'data', 'price'], 0);

    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'net_weight'], 0);

    return {
      acctName,
      eosBal,
      rexBal,
      rexFund,
      rexPrice,
      eosCpu,
      eosNet
    };
  },
  dispatch => ({
    sellRex: id => dispatch(walletActions.sellRexRoutine({ id }))
  }),
)(UnLend));