import { createRoutine } from 'redux-saga-routines';

export const getEmtUnstakeRoutine = createRoutine('emt/UNSTAKE_EMT');


export const getEmtStakeRoutine = createRoutine('emt/STAKE_EMT');


export const getEmtBalanceRoutine = createRoutine('emt/GET_EMT_BALANCE');


export const claimEmtRoutine = createRoutine('emt/CLAIM_EMT');
