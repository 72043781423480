/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-await-in-loop */
import axios from 'axios';

const resultObj = {};

export function* clientGetAllBalances({ account_name }) {
  const allBalancesArray = [yield getEosData({ account_name }), yield getEosRexData({ account_name }), yield getBoidData({ account_name }), yield getBrmData({ account_name }), yield getHvtData({ account_name }), yield getTrybeData({ account_name }),
    yield getPixeosData({ account_name }), yield getSeedData({ account_name }), yield getEfxData({ account_name }), yield getEmtData({ account_name }), yield getPeosData({ account_name })];

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  asyncForEach(allBalancesArray, async (contact) => {
    return contact;
  });


  return resultObj;
}

//============
// EOS
//============
export function* getEosData({ account_name }) {
  try {
    const eosBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'eosio.token',
      table: 'accounts',
    });
    resultObj.eosRows = eosBalance.data;

    const eosStakedBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'eosio',
      table: 'delband',
    });
    resultObj.eosStakedRows = eosStakedBalance.data;

    const getAccount = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_account`, {
      account_name,
    });
    resultObj.accountRows = getAccount.data;

    const eosRefund = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'eosio',
      table: 'refunds',
    });
    resultObj.eosRows.refund = eosRefund.data;

    const eosNetLoans = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'netloan',
      index_position: 3,
      key_type: 'name',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.eosRows.netloans = eosNetLoans.data;

    const eosCpuLoans = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'cpuloan',
      index_position: 3,
      key_type: 'name',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.eosRows.cpuloans = eosCpuLoans.data;
  } catch (e) {
    console.error('Error Retrieving EOS Data', e);
  }
  return resultObj;
}


//============
// EOS REX
//============
export function* getEosRexData({ account_name }) {
  //const resultObj = {};
  try {
    const rexBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'rexbal',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.rexRows = rexBalance.data;

    const rexFund = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'rexfund',
      lower_bound: account_name,
      upper_bound: account_name
    });
    resultObj.rexRows.rexFunds = rexFund.data;

    const rexPool = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'eosio',
      code: 'eosio',
      table: 'rexpool'
    });
    resultObj.rexRows.rexPool = rexPool.data;
  } catch (e) {
    console.error('Error Retrieving REX Data', e);
  }
  return resultObj;
}


//============
// BRM
//============
export function* getBrmData({ account_name }) {
  //const resultObj = {};

  try {
    const brmBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'openbrmeos11',
      table: 'accounts',
    });
    resultObj.brmRows = brmBalance.data;

    const brmStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'openbrmeos11',
      code: 'openbrmeos11',
      table: 'stakes',
      lower_bound: account_name,
      upper_bound: account_name,
    });
    resultObj.brmRows.stakedBRM = brmStaked.data;

    const brmRefunds = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'openbrmeos11',
      table: 'lockedbals',
    });
    resultObj.brmRows.refunds = brmRefunds.data;
  } catch (e) {
    console.error('Error Retrieving BRM Data', e);
  }
  return resultObj;
}


//============
// TRYBE
//============
export function* getTrybeData({ account_name }) {
  //const resultObj = {};
  try {
    const trybeBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'trybenetwork',
      table: 'accounts',
    });
    resultObj.trybeRows = trybeBalance.data;

    const trybeStakedBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'trybenetwork',
      table: 'stake',
    });
    resultObj.trybeRows.trybeStaked = trybeStakedBalance.data;

    const trybeRefundStatus = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'trybenetwork',
      table: 'refunds',
    });
    resultObj.trybeRows.trybeRefund = trybeRefundStatus.data;

    const onsiteBalance = yield axios.get(`${process.env.REACT_APP_TRYBE_URL}?account=${account_name}`, {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` }
    });
    resultObj.trybeRows.onsite = onsiteBalance.data;
  } catch (e) {
    console.error('Error Retrieving Trybe Data', e);
  }
  return resultObj;
}


//============
// PIXEOS
//============
export function* getPixeosData({ account_name }) {
  //const resultObj = {};
  try {
    const pixeosBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'pixeos1token',
      table: 'accounts',
    });
    resultObj.pixeosRows = pixeosBalance.data;

    const pixeosStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'pixeos1stake',
      code: 'pixeos1stake',
      table: 'stakes',
      lower_bound: account_name,
      upper_bound: account_name,
    });
    resultObj.stakedPixeos = pixeosStaked.data;
  } catch (e) {
    console.error('Error Retrieving Pixeos Data', e);
  }
  return resultObj;
}


//============
// KARMA
//============
export function getKarmaData() {
  //const resultObj = {};
  /*
  try {
    const karmaStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'therealkarma',
      table: 'powered'
    });
    resultObj.karmaRows.stakedKarma = karmaStaked.data;
  } catch(e) {
    console.error('Error retrieving karmaStaked', e);
  }

  try {
    const karmaRefunding = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'therealkarma',
      table: 'refunding'
    });
    resultObj.karmaRows.refunding = karmaRefunding.data;
  } catch(e) {
    console.error('Error retrieving karma refunding', e);
  }
  */
  return resultObj;
}


//============
// HVT
//============
export function* getHvtData({ account_name }) {
  //const resultObj = {};
  try {
    const hvtBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'hirevibeshvt',
      table: 'accounts',
    });
    resultObj.hvtRows = hvtBalance.data;

    const hvtResources = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'hvtstakingio',
      table: 'resources'
    });
    resultObj.hvtRows.staked = hvtResources.data;
  } catch (e) {
    console.error('Error Retrieving HVT Data', e);
  }
  return resultObj;
}


//============
// SEED
//============
export function* getSeedData({ account_name }) {
  //const resultObj = {};
  try {
    const seedBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'parslseed123',
      table: 'accounts',
    });
    resultObj.seedRows = seedBalance.data;

    const seedStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'parslseed123',
      table: 'stake'
    });
    resultObj.seedRows.staked = seedStaked.data;

    const seedRefund = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'parslseed123',
      table: 'refund'
    });
    resultObj.seedRows.refund = seedRefund.data;
  } catch (e) {
    console.error('Error Retrieving Seed Data', e);
  }
  return resultObj;
}


//============
//EFX
//============
export function* getEfxData({ account_name }) {
  //const resultObj = {};
  try {
    const efxBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'effecttokens',
      table: 'accounts',
    });
    resultObj.efxRows = efxBalance.data;

    const efxStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'efxstakepool',
      table: 'stake',
    });
    resultObj.efxRows.staked = efxStaked.data;

    const efxUnstaking = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'efxstakepool',
      table: 'unstake',
    });
    resultObj.efxRows.unstaking = efxUnstaking.data;
  } catch (e) {
    console.error('Error Retrieving EFX Data', e);
  }
  return resultObj;
}

//============
// EMT | MNX
//============
export function* getEmtData({ account_name }) {
  //const resultObj = {};
  try {
    const emtBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'emanateoneos',
      table: 'accounts',
    });
    resultObj.emtRows = emtBalance.data;

    const emtStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'emanateoneos',
      lower_bound: account_name,
      upper_bound: account_name,
      code: 'emanateoneos',
      table: 'accgrowdb'
    });
    resultObj.emtRows.staked = emtStaked.data;

    const emtPresale = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'emanateoneos',
      lower_bound: account_name,
      upper_bound: account_name,
      code: 'emanateoneos',
      table: 'accstakedb'
    });
    resultObj.emtRows.presale = emtPresale.data;

    const mnxBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'emanatenekot',
      table: 'accounts',
    });
    resultObj.emtRows.mnx = mnxBalance.data;
  } catch (e) {
    console.error('Error Retrieving EMT DATA', e);
  }
  return resultObj;
}


//============
// PEOS
//============
export function* getPeosData({ account_name }) {
  //const resultObj = {};
  try {
    const peosBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'thepeostoken',
      table: 'accounts',
    });
    resultObj.peosRows = peosBalance.data;

    const peosDividends = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: 'thepeostoken',
      code: 'thepeostoken',
      table: 'dividends'
    });
    resultObj.peosRows.dividends = peosDividends.data;

    const peosStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'thepeostoken',
      table: 'staked'
    });
    resultObj.peosRows.staked = peosStaked.data;

    const peosRefund = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'thepeostoken',
      table: 'refunds'
    });
    resultObj.peosRows.refunding = peosRefund.data;
  } catch (e) {
    console.error('Error Retrieving PEOS DATA', e);
  }
  return resultObj;
}


//============
// Boid
//============
export function* getBoidData({ account_name }) {
  try {
    const boidBalance = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'boidcomtoken',
      table: 'accounts',
    });
    resultObj.boidRows = boidBalance.data;

    const boidStaked = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'boidcomtoken',
      table: 'staked',
      limit: -1
    });
    resultObj.boidRows.staked = boidStaked.data;

    const boidDelegation = yield axios.post(`https://${process.env.REACT_APP_WALLET_HOST}/v1/chain/get_table_rows`, {
      json: 'true',
      scope: account_name,
      code: 'boidcomtoken',
      table: 'delegation',
    });
    resultObj.boidRows.delegation = boidDelegation.data;
  } catch (e) {
    console.error('Error Retrieving Boid Data', e);
  }
  return resultObj;
}