import { put, takeLatest } from 'redux-saga/effects';
import * as seedActions from './seedActions';
import { getSeedData } from '../../accountData/fetchClientApi';
import * as walletActions from '../../wallet/walletActions';
import * as walletClientApi from "../../wallet/walletClientApi";
import { showErrorAlert, showProcessAlert, showSuccessAlert } from "../../alerts/showAlert";

export const getSeedWatches = [
  getSeedBalanceWatch,
  getSeedStakeWatch,
  getSeedUnstakeWatch,
  getSeedRefundWatch
];


export function* getSeedRefundWatch() {
  yield takeLatest(seedActions.getSeedRefundRoutine.TRIGGER, seedRefund);
}
export function* getSeedBalanceWatch() {
  yield takeLatest(seedActions.getSeedBalanceRoutine.TRIGGER, seedBalance);
}
export function* getSeedStakeWatch() {
  yield takeLatest(seedActions.getSeedStakeRoutine.TRIGGER, seedStake);
}
export function* getSeedUnstakeWatch() {
  yield takeLatest(seedActions.getSeedUnstakeRoutine.TRIGGER, seedUnstake);
}

function* seedRefund({ payload }) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload)
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'parslseed123',
    method: 'refund',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getSeedData({account_name: payload.id.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(seedActions.getSeedRefundRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(seedActions.getSeedRefundRoutine.failure(serverResponse));
  }
}

function* seedBalance({ payload }) {
  console.log(payload)
  const serverResponse = yield getSeedData(payload);
  console.log('response', serverResponse);
  if (serverResponse) {
    yield put(seedActions.getSeedBalanceRoutine.success(serverResponse));
  } else {
    yield put(seedActions.getSeedBalanceRoutine.failure(serverResponse));
  }
}
function* seedStake({ payload }) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'parslseed123',
    method: 'stake',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getSeedData({account_name: payload.id.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(seedActions.getSeedStakeRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(seedActions.getSeedStakeRoutine.failure(serverResponse));
  }
}

function* seedUnstake({ payload }) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload)
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'parslseed123',
    method: 'unstake',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(seedActions.getSeedUnstakeRoutine.success(serverResponse));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(seedActions.getSeedUnstakeRoutine.failure(serverResponse));
  }
}