import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import * as boidActions from './boidActions';
import { showErrorAlert } from "../../alerts/showAlert";

class KarmaBody extends Component {
  constructor() {
    super();
    this.state = {
      stakeAmount: '',
      unstakeAmount: '',
      claimStake: '',
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { unstakeAmount, stakeAmount, claimStake } = this.state;
    const { acctName, stakeToken, unstakeToken, tokensymbal, acctBal, acctStaked, claimToken } = this.props;
    const amountDefault = '0.0000';

    return (
      <div className="row">
        <div className="col col-12 col-md-4 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">Claim</h5>
            <Popup
              content="Re-invest: adjust percentage to be staked automatically"
              position="top right"
              trigger={
                <img className="st-card__popup" src="../assets/images/info-icon.svg" alt="info" />
              }
            />
            <label className="st-input-wrap" htmlFor="claimStake">
              <input
                disabled
                id="claimStake"
                className="st-input"
                type="range"
                placeholder="0"
                min="0"
                max="100"
                step="5"
                value={claimStake}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">Re-invest Percentage {claimStake ? claimStake : 0}% (DISABLED) </span>
            </label>
            <button
              type="button"
              className="st-button st-button--blue st-button--large"
              disabled={!acctName}
              onClick={() => {
                claimToken({
                  stake_account: acctName,
                  percentage_to_stake: claimStake,
                  issuer_claim: false
                });
              }}
            >
              {acctName ? 'Claim' : '(Connect A Wallet)'}
            </button>
          </div>
        </div>
        <div className="col col-12 col-md-4 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">Stake</h5>
            <label className="st-input-wrap" htmlFor="stakeAmount">
              <input
                disabled={!acctName}
                id="stakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={amountDefault}
                value={stakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">Stake Amount</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                parseFloat(parseFloat(stakeAmount).toFixed(4)) > parseFloat(parseFloat(acctBal).toFixed(4)) ? showErrorAlert(`You are trying to stake more then you have!`)
                  : stakeToken({
                    from: acctName,
                    to: acctName,
                    quantity: parseFloat(stakeAmount).toFixed(4) + ' ' + tokensymbal,
                    time_limit: 0,
                    use_staked_balance: false
                  });
              }}> {acctName ? 'Stake' : '(Connect A Wallet)'}
            </button>
          </div>
        </div>
        <div className="col col-12 col-md-4 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">Unstake</h5>
            <Popup
              content="Refund Time: Instant"
              position="top right"
              trigger={
                <img className="st-card__popup" src="../assets/images/info-icon.svg" alt="info" />
              }
            />
            <label className="st-input-wrap" htmlFor="unstakeAmount">
              <input
                disabled={!acctName}
                id="unstakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={amountDefault}
                value={unstakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">Unstake Amount</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                parseFloat(parseFloat(unstakeAmount).toFixed(4)) > parseFloat(parseFloat(acctStaked).toFixed(4)) ? showErrorAlert(`You are trying to stake more then you have!`)
                  : unstakeToken({
                    from: acctName,
                    to: acctName,
                    time_limit: 0,
                    to_staked_balance: false,
                    issuer_unstake: false,
                    transfer: false,
                    quantity: parseFloat(unstakeAmount).toFixed(4) + ' ' + tokensymbal,
                    use_staked_balance: false
                  });
              }}> {acctName ? 'Unstake' : '(Connect A Wallet)'}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const acctBal = state.data.getIn(['accountInfo', 'boidRows', 'rows', 0, 'balance'], 0);
    const acctStaked = state.data.getIn(['accountInfo', 'boidRows', 'staked', 'rows', 0, 'quantity'], 0);

    return {
      acctName,
      acctBal,
      acctStaked
    };
  },
  dispatch => ({
    stakeToken: id => dispatch(boidActions.stakeTokenRoutine({ id })),
    unstakeToken: id => dispatch(boidActions.unstakeTokenRoutine({ id })),
    claimToken: id => dispatch(boidActions.claimTokenRoutine({ id }))
  })
)(KarmaBody));