import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux';
import WalletReducer from '../wallet/walletReducer';
import priceReducer from '../prices/pricesReducer';

export default combineReducers({
  form: formReducer,
  data: WalletReducer,
  prices: priceReducer
});
