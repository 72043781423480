/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
// Main Header
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Grid, Button, Header, Message, Modal } from 'semantic-ui-react';
//import Rotator from './rotator';
import * as walletActions from '../wallet/walletActions';

class HeaderMessage extends Component {
  state = { visible: true, open: false }

  handleDismiss = () => {
    this.setState({ visible: false });
  }

  show = () => this.setState({ open: true })

  close = () => this.setState({ open: false })

  routeChange = () => {
    this.props.history.push('/rex/');
  }

  render() {
    const { acctName, is_error, title, message, message_type, btnText, sendDonation, signInToWallet } = this.props;
    const donateTo = "stakerone.x";
    const hideOnPages = ['/account', '/rex', '/rex/'];

    const donateOptions = [
      { name: "1 EOS", amount: "1.0000 EOS" },
      { name: "5 EOS", amount: "5.0000 EOS" },
      { name: "25 EOS", amount: "25.0000 EOS" },
      { name: "50 EOS", amount: "50.0000 EOS" },
      { name: "100 EOS", amount: "100.0000 EOS" },
    ];

    const genDonations = items => items.map((item) => {
      return (
        <Button
          content={item.name}
          key={item.name}
          className="st-button--blue donate-btn"
          onClick={() => {
            sendDonation({
              from: acctName,
              to: donateTo,
              quantity: item.amount,
              memo: "Staker Donation",
            })
          }}
        />
      );
    });
    const mainContent = () => {
      return (
        <div>
          {!is_error && (
            <Grid doubling columns={2} className="st-card">
              <Grid.Column floated="left" width={10}>
                <Header as="h2">
                  <Header.Content>
                    {title}
                    <Header.Subheader>
                      {message}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Button
                  as="a"
                  content={btnText}
                  fluid
                  circular
                  hidden={message_type == 3}
                  className={message_type == 1 ? "st-button st-button--blue-solid st-button--large" : "st-button st-button--red st-button--large"}
                  onClick={() => {
                    return this.routeChange()
                  }}
                />
              </Grid.Column>
            </Grid>
          )}
        </div>
      )
    }
    return (
      <div hidden>
        {/*<Rotator status={this.props.history.location.pathname == "/swap" || this.props.history.location.pathname == "/account"} /> */}
        {this.props.history.location.pathname != "/swap" && title && (
          <div className="st-top-header" hidden={!acctName || hideOnPages.indexOf(this.props.history.location.pathname) !== -1}>
            <Message
              hidden={!this.state.visible || is_error}
              onDismiss={this.handleDismiss}
              content={mainContent}
              className="header-message"
            />
            <Modal size="tiny" open={this.state.open} onClose={this.close}>
              <Modal.Content>
                <h1>Support Stakerone</h1>
                <p>Choose an amount below that you will like to donate to <b>stakerone.x</b></p>
              </Modal.Content>
              <Modal.Actions>
                {!acctName && (
                  <Button
                    content="Connect Scatter"
                    style={{margin: 'auto'}}
                    className="st-button st-button--blue st-button--large"
                    onClick={() => {
                      signInToWallet({ data: 0 });
                    }}
                  />
                )}
                {acctName && genDonations(donateOptions)}
              </Modal.Actions>
            </Modal>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const is_error = state.data.getIn(['messages', 'latest', 'is_error']);
    const title = state.data.getIn(['messages', 'latest', 'details', 'title']);
    const message = state.data.getIn(['messages', 'latest', 'details', 'message']);
    //Button Info
    const btnText = state.data.getIn(['messages', 'latest', 'button', 'text']);
    const btnLink = state.data.getIn(['messages', 'latest', 'button', 'link']);

    const message_type = state.data.getIn(['messages', 'latest', 'type']);
    return {
      acctName,
      is_error,
      title,
      message,
      btnText,
      btnLink,
      message_type
    };
  },
  dispatch => ({
    signInToWallet: (args) => {
      dispatch(walletActions.signInToWalletRoutine(args));
    },
    sendDonation: (args) => {
      dispatch(walletActions.transferEosRoutine(args));
    },
  })
)(HeaderMessage));