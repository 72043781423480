import axios from 'axios';

export function* getMessagesApi() {
  const getMessages = {};
  try {
    const getLatestStatus = yield axios.get('https://atomlaunch.com/wp-json/v1/staker-one/message');
    getMessages.latest = getLatestStatus.data;
  } catch (e) {
    console.error('Error retrieving latest status', e);
  }
  return getMessages;
}