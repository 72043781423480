/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import queryString from 'query-string';

import { Button, Image, Dropdown, Popup } from 'semantic-ui-react';

import * as walletActions from '../wallet/walletActions';

class LoginAction extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = { value: 1, isLynx: false, theme: cookies.get('theme') };
  }

  componentDidMount() {
    const url = this.props.location.search;
    const params = queryString.parse(url);


    if (params.wallet) {
      this.setState({
        value: params.wallet == 'scatter' ? 0
          : params.wallet == 'AnchorLink' ? 1
            : params.wallet == 'lynx' ? 2
              : params.wallet == 'meetone' ? 3
                : params.wallet == 'tp' ? 4
                  : 0
      });
    }
  }


  handleChange = (e, { value }) => this.setState({ value });


  handleThemeChange(theme) {
    const { cookies } = this.props;

    cookies.set('theme', theme, { path: '/' });
    this.setState({ theme });
    this.props.themeHandler(theme);
  }

  walletLogin = () => {
    const { signInToWallet, acctName } = this.props;
    const { value } = this.state;
    // this.setState({ loading: true });
    !acctName && signInToWallet({ data: value });
  }

  walletLogout = () => {
    const { signOutOfWallet } = this.props;
    // this.setState({ loading: false });
    signOutOfWallet();
  }

  render() {
    const { signInToWallet, signOutOfWallet, acctName } = this.props;
    const { value, theme, isLynx } = this.state;

    window.addEventListener("lynxMobileLoaded", () => {
      // lynx is on the window and ready!
      !acctName && signInToWallet({ data: 2 });
      this.setState({ value: 2, isLynx: true });
      console.log("LYNX Loaded")
    });

    const options = [
      { text: 'Scatter', value: 0, image: { avatar: true, src: process.env.PUBLIC_URL + '/assets/images/scatter-icon.svg' } },
      { text: 'Anchor', value: 1, image: { avatar: true, src: process.env.PUBLIC_URL + "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg viewBox='0 0 160 160' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='80' cy='80' r='80' fill='%233650A2'/%3E%3Cpath d='m80.006 30c2.2988 0 4.3956 1.3134 5.3987 3.3818l24.006 49.5 0.542 1.1178h-13.337l-3.8797-8h-25.461l-3.8798 8h-13.337l0.5423-1.1182 24.006-49.5c1.0031-2.0684 3.0998-3.3818 5.3986-3.3818zm-7.8807 36h15.761l-7.8807-16.25-7.8807 16.25zm25.874 35h12.006c-0.361 16.152-13.731 29-29.999 29s-29.638-12.848-29.998-29h12.005c0.281 7.354 5.1673 13.631 11.987 15.996v-23.996c0-3.3137 2.6863-6 6-6s6 2.6863 6 6v24c6.8262-2.362 11.718-8.641 11.999-16z' clip-rule='evenodd' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E%0A" } },
      { text: 'Lynx', value: 2, image: { avatar: true, src: process.env.PUBLIC_URL + '/assets/images/wallet/eos_lynx_wallet_logo.svg' } },
      { text: 'Meetone', value: 3, image: { avatar: true, src: process.env.PUBLIC_URL + '/assets/images/wallet/meetone_wallet_logo.svg' } },
      { text: 'TokenPocket', value: 4, image: { avatar: true, src: process.env.PUBLIC_URL + '/assets/images/tp-icon.svg' } },
    ].sort((a, b) => (a.text > b.text) ? 1 : -1);

    const currentCntOption = options.filter(option => option.value == value).map(option => option.text);
    const getWalletIcon = options.filter(option => option.value == value).map(option => option.image.src);

    const themeToggle = (
      <div className="st-login-wrap--theme-switch">
        <button
          basic
          circular
          icon
          className="st-button st-button--theme-switcher"
          type="button"
          onClick={() => {
            this.handleThemeChange(theme == 'dark' ? 'light' : 'dark')
          }}
        >
          <Image src={theme == 'dark' ? '../assets/theme/dark-on.svg' : '../assets/theme/dark-off.svg'} width="22px" />
        </button>
      </div>
    )


    return (
      <div>
        <div className="st-login-wrap" hidden={acctName}>
          <Image
            src={process.env.PUBLIC_URL + "/assets/images/chevron-blue-right.svg"}
            className="btn-arrow header-arrow" />
          <Button
            className="st-button st-button--login"
            onClick={() => {
              !acctName && !isLynx && value == 2 ? window.open('https://magic.lynxwallet.io/2uqU') : signInToWallet({ data: value });
            }}>
            <Image src={getWalletIcon} style={{ height: '20px' }} />Connect
            {currentCntOption.length > 0 && <span> with {currentCntOption}</span>}
          </Button>
          <Dropdown
            header="Select A Wallet"
            className="icon"
            inline
            options={options}
            onChange={this.handleChange}
            value={value}
          />
          {themeToggle}
        </div>

        <div
          className="st-login-wrap"
          hidden={!acctName}
        >
          <div className="st-button st-button--login st-button--success">
            <Image src={getWalletIcon} style={{ height: '20px' }} />
            {acctName}<span> | Connected</span>
          </div>

          <button
            type="button"
            className="st-logout"
            onClick={() => {
              acctName && signOutOfWallet();
            }}
          >
            <Popup
              content="logout"
              position="bottom right"
              trigger={
                <Image src={theme == 'dark' ? process.env.PUBLIC_URL + "/assets/images/logout-icon-dark.svg" : process.env.PUBLIC_URL + "/assets/images/logout-icon.svg"} alt="logout" />
              }
            />
          </button>
          {themeToggle}
        </div>
      </div>
    )
  }
}

export default withCookies(withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    return {
      acctName
    };
  },
  dispatch => ({
    signInToWallet: (args) => {
      dispatch(walletActions.signInToWalletRoutine(args));
    },
    signOutOfWallet: (args) => {
      dispatch(walletActions.signOutOfWalletRoutine(args));
    },
    getAllBalances: (args) => {
      dispatch(walletActions.getAccountBalancesRoutine(args));
    },
  })
)(LoginAction)));