import { createRoutine } from 'redux-saga-routines';

export const getEfxUnstakeRoutine = createRoutine('efx/UNSTAKE_EFX');


export const getEfxStakeRoutine = createRoutine('efx/STAKE_EFX');


export const getEfxBalanceRoutine = createRoutine('efx/GET_EFX_BALANCE');


export const claimEfxRoutine = createRoutine('efx/CLAIM_EFX');
