/* eslint-disable react/destructuring-assignment */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-arrow-callback */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Image, Tab, Popup, Menu, Icon } from 'semantic-ui-react';
import * as walletActions from '../../../wallet/walletActions';
import LendContent from './content/lend';
import Borrow from './content/borrow';
import ManageOptions from './content/manage';

class EosRex extends Component {
  state = {
    activeIndex: 0,
    rexBuyAmount: '',
    borrowFrom: 0
  };

  changeBorrow = (event) => {
    this.setState({
      borrowFrom: event.target.value,
    });
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  btnTabChange = e => this.setState({ activeIndex: e.target.value });

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {
    const { activeIndex, rexBuyAmount, borrowFrom } = this.state;
    const { eosBal, rexBal, rexFund, RexPrice, cpuLoans, netLoans, didVote, voteProxy, acctName, borrowEos } = this.props;
    const rexDefault = '0.0000';
    const cpuLoanData = cpuLoans.toJS();
    const netLoanData = netLoans.toJS();
    let totalBorrowed = 0;

    cpuLoanData.forEach((obj) => {
      totalBorrowed += parseFloat(obj.total_staked);
    });
    netLoanData.forEach((obj) => {
      totalBorrowed += parseFloat(obj.total_staked);
    });

    const panes = [
      {
        menuItem: (
          <Menu.Item key="fund" className="fund">
            <span>1. FUND</span>
            <h5>WITHDRAW/DEPOSIT</h5>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}>{fund}</Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key="lend" className="lend">
            <span>2. LEND</span>
            <h5>BUY REX</h5>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}><LendContent RexPrice={RexPrice} /></Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key="borrow" className="borrow">
            <span>3. BORROW</span>
            <h5>CPU AND NET</h5>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}><Borrow /></Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key="manage">
            <span>MANAGE</span>
            <h5>REX FUND</h5>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}><ManageOptions /></Tab.Pane>
      },
    ];

    const fund = (
      <div className="st-pannel-inner st-card__lend">
        <div className="row d-flex justify-content-center">
          <div className="col col-md-6">
            <button
              type="button"
              className={`st-button ${borrowFrom == 0 ? "st-button--blue" : ""}`}
              value={0}
              onClick={this.changeBorrow}
            >
              DEPOSIT
            </button>
          </div>
          <div className="col col-md-6">
            <button
              type="button"
              className={`st-button ${borrowFrom == 1 ? "st-button--blue" : ""}`}
              value={1}
              onClick={this.changeBorrow}
            >
              WITHDRAW
            </button>
          </div>
        </div>
        <div className="row d-flex justify-content-center mt-5">
          <div className="col col-md-12 text-center">
            <input
              className="st-input st-input--number"
              type="number"
              id="rexBuyAmount"
              placeholder={rexDefault}
              value={rexBuyAmount}
              onChange={this.onChangeInput}
              autoComplete="off"
            />
            <p className="st-card__bold ">{borrowFrom == 0 ? `LIQUID EOS: ${eosBal}` : `EOS IN YOUR FUND: ${rexFund}`}</p>
          </div>
        </div>
        <button
          className="st-button st-button--large st-button--purple"
          type="button"
          size="huge"
          disabled={!acctName}
          onClick={() => {
            borrowEos({
              method: borrowFrom == 0 ? "deposit" : "withdraw",
              data: {
                owner: acctName,
                amount: parseFloat(rexBuyAmount).toFixed(4) + ' EOS'
              },
              acctName
            })
          }}> {borrowFrom == 0 ? 'DEPOSIT TO FUND' : 'WITHDRAW FROM FUND'}
        </button>
      </div>
    );

    // Sidebar
    const sidebar = (
      <div className="st-card-rex__left">
        <h5 className="st-card__headline">REX (Advance View)</h5>
        <Popup
          content="I'm T-REX"
          position="top left"
          trigger={
            <Image className="st-resources__icon" src="../assets/images/rex-icon.svg" alt="min" />
          }
        />
        <h4 className="st-resources__title st-card__info">
          REX/EOS
          <Popup
            content="Current value of REX token priced in EOS."
            position="top left"
            trigger={
              <img className="st-card__info-icon" src="../assets/images/info-icon.svg" alt="info" />
            }
          />
        </h4>
        <h3 className="st-resources__balance">{RexPrice && parseFloat(RexPrice.price).toFixed(4) + ' EOS'}</h3>
        <div className="proxy">
          <h5 className="st-card__info">
            <span>
              VOTE REQUIREMENT
            </span>
            <Popup
              content="Must vote for at least 21 block producers or vote for a proxy to buy REX"
              position="top left"
              trigger={
                <img className="st-card__info-icon" src="../assets/images/info-icon.svg" alt="info" />
              }
            />
          </h5>
          <div className="row st-card-rex__buttons">
            <div className="col col-md-6">
              {didVote}
            </div>
            <div className="col col-md-6">
              <button
                className="st-button st-button--grey"
                type="button"
                value="3"
                disabled={!acctName}
                onClick={() => {
                  voteProxy({
                    voter: acctName,
                    proxy: process.env.PROXY,
                    producers: ''
                  })
                }}>
                VOTE PROXY
              </button>
            </div>
          </div>
        </div>
        <h5 className="st-card__info">
          <span>
            {process.env.REACT_APP_NETWORK} IN YOUR FUND (UNLENT)
          </span>
          <Popup
            content="Amount of unlent EOS in your Fund, used to buy REX"
            position="top left"
            trigger={
              <img className="st-card__info-icon" src="../assets/images/info-icon.svg" alt="info" />
            }
          />
        </h5>
        <p className="st-card__bold-color">{rexFund ? rexFund : rexFund + ' EOS'}</p>
        <div className="row st-card-rex__buttons">
          <div className="col col-md-12">
            <button className="st-button st-button--grey" type="button" value="0" onClick={this.btnTabChange}>
              DEPOSIT
            </button>
          </div>
        </div>

        <h5 className="st-card__info">
          <span>
            REX IN YOUR FUND
          </span>
          <Popup
            content="Amount of REX in your Fund received by lending EOS to pool."
            position="top left"
            trigger={
              <img className="st-card__info-icon" src="../assets/images/info-icon.svg" alt="info" />
            }
          />
        </h5>
        <p className="st-card__bold-color">{rexBal ? rexBal : rexBal + ' REX'}</p>
        <div className="row st-card-rex__buttons">
          <div className="col col-md-6">
            <button className="st-button st-button--grey" type="button" value="1" onClick={this.btnTabChange}>
              BUY REX
            </button>
          </div>
          <div className="col col-md-6">
            <button className="st-button st-button--grey" type="button" value="3" onClick={this.btnTabChange}>
              SELL REX
            </button>
          </div>
        </div>

        <h5 className="st-card__info">
          <span>
            BORROWED {process.env.REACT_APP_NETWORK}
          </span>
          <Popup
            content="Amount of EOS You've Borrowed"
            position="top left"
            trigger={
              <img className="st-card__info-icon" src="../assets/images/info-icon.svg" alt="info" />
            }
          />
        </h5>
        <p className="st-card__bold-color">{totalBorrowed.toFixed(4)} EOS</p>
        <div className="row st-card-rex__buttons">
          <div className="col col-md-6">
            <button className="st-button st-button--grey" type="button" value="2" onClick={this.btnTabChange}>
              RENT CPU
            </button>
          </div>
          <div className="col col-md-6">
            <button className="st-button st-button--grey" type="button" value="2" onClick={this.btnTabChange}>
              RENT NET
            </button>
          </div>
        </div>
      </div>
    );

    return (
      <div className="st-main-body-inner">
        <div className="st-main-body-rex">
          <div className="st-card st-card-rex">
            {sidebar}
            <div className="st-card-rex__right">
              <Tab menu={{ secondary: true, pointing: true, widths: 4, size: 'massive' }} panes={panes} activeIndex={activeIndex} onTabChange={this.handleTabChange} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'rex_balance'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);
    const cpuLoans = state.data.getIn(['accountInfo', 'eosRows', 'cpuloans', 'rows'], List());
    const netLoans = state.data.getIn(['accountInfo', 'eosRows', 'netloans', 'rows'], List());

    const voteBp = state.data.getIn(['accountInfo', 'accountRows', 'voter_info', 'producers'], List());
    const voteProxy = state.data.getIn(['accountInfo', 'accountRows', 'voter_info', 'proxy'], '');
    const didVote = voteBp.toArray().length > 0 ? <p className="st-resources__green"><small><Icon name="check" /></small> MET</p> : voteProxy ? <p className="st-resources__green"><small><Icon name="check" /></small> MET</p> : <p className="st-resources__red"><small><Icon name="exclamation triangle" /></small> NOT MET</p>;

    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'net_weight'], 0);
    const eosCpuUsed = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'used'], 0);
    const eosCpuMax = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'max'], 0);
    const eosNetUsed = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'used'], 0);
    const eosNetMax = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'max'], 0);
    const eosRamUsed = state.data.getIn(['accountInfo', 'accountRows', 'ram_usage'], 0);
    const eosRamMax = state.data.getIn(['accountInfo', 'accountRows', 'ram_quota'], 0);
    const stakedAccounts = state.data.getIn(['accountInfo', 'eosStakedRows', 'rows'], List());


    const eosCpuTotal = parseFloat(eosCpuUsed / eosCpuMax * 100).toFixed(2);
    const eosNetTotal = parseFloat(eosNetUsed / eosNetMax * 100).toFixed(2);
    const eosRamTotal = Math.round(eosRamUsed / eosRamMax * 100).toFixed(2);

    return {
      acctName,
      eosBal,
      rexBal,
      rexFund,
      eosCpu,
      eosNet,
      eosCpuUsed,
      eosCpuMax,
      eosCpuTotal,
      eosNetUsed,
      eosNetMax,
      eosNetTotal,
      eosRamUsed,
      eosRamMax,
      eosRamTotal,
      stakedAccounts,
      cpuLoans,
      netLoans,
      didVote

    };
  },
  dispatch => ({
    stakeEos: id => dispatch(walletActions.stakeEosRoutine({ id })),
    unStakeEos: id => dispatch(walletActions.unStakeEosRoutine({ id })),
    voteProxy: id => dispatch(walletActions.voteProxyRoutine({ id })),
    borrowEos: id => dispatch(walletActions.borrowRexRoutine({ id }))
  })
)(EosRex));