/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Table, Popup } from 'semantic-ui-react';
import * as walletActions from '../../wallet/walletActions';
import { showErrorAlert } from "../../alerts/showAlert";

class EosioBody extends Component {
  constructor() {
    super();
    this.state = {
      sendToAccount: '',
      unstakeFrom: '',
      cpuStakeAmount: '',
      netStakeAmount: '',
      cpuUnstakeAmount: '',
      netUnstakeAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { cpuStakeAmount, netStakeAmount, sendToAccount, unstakeFrom, cpuUnstakeAmount, netUnstakeAmount } = this.state;
    const { eosBal, acctName, stakeEos, unStakeEos} = this.props;
    const stakedAccounts = this.props.stakedAccounts.toJS();
    const stakeDefault = '0.0000';
    const stakedData = [];
    const renderStakes = items => items.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Table.Row key={item + index}>
          <Table.Cell>
            {item.to}
          </Table.Cell>
          <Table.Cell>
            {item.cpu_weight}
          </Table.Cell>
          <Table.Cell>
            {item.net_weight}
          </Table.Cell>
        </Table.Row>
      );
    });

    stakedAccounts.forEach((obj) => {
      obj.holder = obj.to;
      obj.cpu = obj.cpu_weight;
      obj.net = obj.net_weight;
      stakedData.push(obj);
    });

    return (
      <div>
        <div className="row">
          <div className="col col-12 col-md-12 col-lg-6 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">STAKE EOS</h5>
              <label className="st-input-wrap" form="eosReceiver">
                <input
                  disabled={!acctName}
                  className="st-input"
                  id="sendToAccount"
                  type="text"
                  placeholder=" "
                  value={sendToAccount}
                  onChange={this.onChangeInput}
                  autoComplete="off"
                />
                <span className="st-input-label">STAKE TO (EOS ACCOUNT)</span>
              </label>
              <div className="row">
                <div className="col col-12 col-md-12 col-lg-6">
                  <label className="st-input-wrap" form="cpuAmount">
                    <input
                      disabled={!acctName}
                      id="cpuStakeAmount"
                      className="st-input"
                      type="number"
                      min="0"
                      placeholder={stakeDefault}
                      value={cpuStakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">CPU Stake Amount</span>
                  </label>
                </div>
                <div className="col col-12 col-md-12 col-lg-6">
                  <label className="st-input-wrap" form="netAmount">
                    <input
                      disabled={!acctName}
                      id="netStakeAmount"
                      className="st-input"
                      type="number"
                      min="0"
                      placeholder={stakeDefault}
                      value={netStakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">NET Stake Amount</span>
                  </label>
                </div>
              </div>
              <button
                disabled={!acctName}
                type="button"
                className="st-button st-button--blue st-button--large"
                onClick={() => {
                  parseFloat(parseFloat(cpuStakeAmount).toFixed(4)) > parseFloat(parseFloat(eosBal).toFixed(4))
                  || parseFloat(parseFloat(netStakeAmount).toFixed(4)) > parseFloat(parseFloat(eosBal).toFixed(4))
                  || parseFloat(parseFloat(netStakeAmount).toFixed(4)) + parseFloat(parseFloat(cpuStakeAmount).toFixed(4)) > parseFloat(parseFloat(eosBal).toFixed(4))
                    ? showErrorAlert(`You are trying to stake more then you have!`)
                    : stakeEos({
                      from: acctName,
                      receiver: sendToAccount ? sendToAccount : acctName,
                      stake_net_quantity: netStakeAmount ? parseFloat(netStakeAmount).toFixed(4) + ' EOS' : '0.0000 EOS',
                      stake_cpu_quantity: cpuStakeAmount ? parseFloat(cpuStakeAmount).toFixed(4) + ' EOS' : '0.0000 EOS',
                      transfer: false
                    });
                }}> {acctName ? 'Stake' : '(Connect A Wallet)' }
              </button>
            </div>
          </div>
          <div className="col col-12 col-md-12 col-lg-6 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">UNSTAKE EOS</h5>
              <Popup
                content="Refund Time: 3 Days"
                position="top right"
                trigger={
                  <img className="st-card__popup" src={process.env.PUBLIC_URL + "/assets/images/info-icon.svg"} alt="info" />
                }
              />
              <label className="st-input-wrap" form="eosUnstakeReceiver">
                <input
                  disabled={!acctName}
                  id="unstakeFrom"
                  className="st-input"
                  type="text"
                  placeholder=" "
                  value={unstakeFrom}
                  onChange={this.onChangeInput}
                  autoComplete="off"
                />
                <span className="st-input-label">STAKE FROM (EOS ACCOUNT)</span>
              </label>
              <div className="row">
                <div className="col col-12 col-md-6">
                  <label className="st-input-wrap" form="cpuUnstakeAmount">
                    <input
                      disabled={!acctName}
                      id="cpuUnstakeAmount"
                      className="st-input"
                      type="number"
                      placeholder={stakeDefault}
                      value={cpuUnstakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">CPU Unstake Amount</span>
                  </label>
                </div>
                <div className="col col-12 col-md-12 col-lg-6">
                  <label className="st-input-wrap" form="netUnstakeAmount">
                    <input
                      disabled={!acctName}
                      id="netUnstakeAmount"
                      className="st-input"
                      type="number"
                      placeholder={stakeDefault}
                      value={netUnstakeAmount}
                      onChange={this.onChangeInput}
                      autoComplete="off"
                    />
                    <span className="st-input-label__amount">NET Unstake Amount</span>
                  </label>
                </div>
              </div>
              <button
                disabled={!acctName}
                type="button"
                className="st-button st-button--blue st-button--large"
                onClick={() => {
                  unStakeEos({
                    from: acctName,
                    receiver: unstakeFrom ? unstakeFrom : acctName,
                    unstake_net_quantity: netUnstakeAmount? parseFloat(netUnstakeAmount).toFixed(4) + ' EOS' : '0.0000 EOS',
                    unstake_cpu_quantity: cpuUnstakeAmount? parseFloat(cpuUnstakeAmount).toFixed(4) + ' EOS' : '0.0000 EOS',
                  });
                }}> {acctName ? 'Unstake' : '(Connect A Wallet)' }
              </button>
            </div>
          </div>

          <div className="col col-12 col-md-12 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">STAKED</h5>
              { stakedAccounts[0] && (
                <Table singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell>TO</Table.Cell>
                      <Table.Cell>CPU</Table.Cell>
                      <Table.Cell>NET</Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderStakes(stakedData)}
                  </Table.Body>
                </Table>
              )}
              { !stakedAccounts[0] && (
                <p>Nothing yet.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'net_weight'], 0);
    const eosCpuUsed = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'used'], 0);
    const eosCpuMax = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'max'], 0);
    const eosNetUsed = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'used'], 0);
    const eosNetMax = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'max'], 0);
    const eosRamUsed = state.data.getIn(['accountInfo', 'accountRows', 'ram_usage'], 0);
    const eosRamMax = state.data.getIn(['accountInfo', 'accountRows', 'ram_quota'], 0);
    const stakedAccounts = state.data.getIn(['accountInfo', 'eosStakedRows', 'rows'], List());

    const eosCpuTotal = parseFloat( eosCpuUsed / eosCpuMax * 100).toFixed(2);
    const eosNetTotal = parseFloat( eosNetUsed / eosNetMax * 100).toFixed(2);
    const eosRamTotal = Math.round( eosRamUsed / eosRamMax * 100).toFixed(2);

    return {
      acctName,
      eosBal,
      eosCpu,
      eosNet,
      eosCpuUsed,
      eosCpuMax,
      eosCpuTotal,
      eosNetUsed,
      eosNetMax,
      eosNetTotal,
      eosRamUsed,
      eosRamMax,
      eosRamTotal,
      stakedAccounts
    };
  },
  dispatch => ({
    stakeEos: id => dispatch(walletActions.stakeEosRoutine({id})),
    unStakeEos: id => dispatch(walletActions.unStakeEosRoutine({id}))
  })
)(EosioBody));