import axios from 'axios';

export function* getHistory(payload) {
  const history = {};
  try {
    const historyApi = yield axios.get(`${process.env.REACT_APP_HISTORY_URL}/?network=${process.env.REACT_APP_NETWORK}&account=` + payload, {
      headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` }
    });
    history.data = historyApi.data;
  } catch (e) {
    console.error('Error Retrieving Account History', e);
  }
  return history;
}