/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { withRouter, Redirect, Switch, Route, NavLink, Link } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { instanceOf } from 'prop-types';

import { Button, Image, Dropdown } from 'semantic-ui-react';

//import Rotator from './rotator';
import { List } from 'immutable';
import { getTokenPrice } from './helpers/sortPrices';
import LoginAction from './login.componet';

import * as walletActions from '../wallet/walletActions';
import * as priceActions from '../prices/pricesActions';
import Header from './header';
import Footer from './footer';

import Eosio from '../tokens/eosio/eosio';
import EosRex from '../tokens/rex/advance/eosioRex';
import Boid from '../tokens/boid/boid';
import Seed from '../tokens/seed/seed';
import Efx from '../tokens/efx/efx';
import Emt from '../tokens/emt/emt';
import Peos from '../tokens/peos/peos';

//History
//import Account from './account';

//Request
import Request from './request';

//Error Page
import Error from './error/error';

class MainBody extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    const { cookies } = props;
    this.state = { visible: false, value: 1, search: '', networks: [{ disabled: false, text: "TELOS", url: "https://staker.one/", value: 0, image: { avatar: true, src: "https://atomlaunch.com/wp-content/uploads/2020/01/telos.png" }}, { disabled: false, text: "EOS", url: "https://eos.staker.one", value: 1, image: { avatar: true, src: "https://atomlaunch.com/wp-content/uploads/2020/01/eos.png" }}], theme: cookies.get('theme') };
    this.themeHandler = this.themeHandler.bind(this);
  }

  componentDidMount() {
    const { getTokenPrices, acctName, getMessages } = this.props;
    const { theme } = this.state;
    getMessages();

    if (theme == 'dark') {
      document.documentElement.setAttribute("data-theme", 'dark');
    }

    if (acctName) {
      //getAllBalances(acctName);
    }

    if (!acctName) {
      //signInToWallet(acctName);
      getTokenPrices();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.visible !== nextState.visible) {
      return true;
    }

    if (this.state.theme !== nextState.theme) {
      document.documentElement.setAttribute("data-theme", nextState.theme);
    }

    return true;
  }

  themeHandler(themeStyle) {
    const { cookies } = this.props;

    cookies.set('theme', themeStyle, {path: "/", maxAge: 1000000});
    this.setState({ theme: themeStyle });
  }


  handleChange = (e, { value }) => value != this.state.value ? window.location = this.state.networks[value].url : null;

  toggleMenu = () => this.setState(prevState => ({ visible: !prevState.visible, search: '' }));

  onKeyPressed = (e) => { console.log(e.keyCode) };

  updateSearch(event) {
    this.setState({ search: event.target.value.substr(0, 20) });
  }

  render() {
    const { acctName, tokenPrices } = this.props;
    const { visible, value, search, networks, theme } = this.state;
    const menuIconSize = { width: '4rem' };
    const whitelist = ['/rex'];

    //value == 1 && !acctName && signInToWallet({ data: 1 });
    // value == 3 && !acctName && signInToWallet({ data: 3 });

    if (!acctName && whitelist.indexOf(this.props.history.location.pathname) !== -1) {
      return <Redirect to="/" />;
    }

    if (this.props.history.location.pathname == "/stake" || this.props.history.location.pathname == "/token" || this.props.history.location.pathname == "/") {
      return <Redirect to="/token/eos" />;
    }

    const allTokens = [
      { name: "", url: "/token/eos", tokenName: "EOS", imageName: theme == 'dark' ? process.env.PUBLIC_URL + "/assets/images/eos-logo-white.svg" : process.env.PUBLIC_URL + "/assets/images/eos-logo.svg" },
      { name: "EFX", url: "/token/efx", tokenName: "EFX", imageName: process.env.PUBLIC_URL + "/assets/logos/efx.png" },
      { name: "SEED", url: "/token/seed", tokenName: "SEED", imageName: process.env.PUBLIC_URL + "/assets/logos/seed.png" },
      { name: "EMANATE", url: "/token/emt", tokenName: "EMT", imageName: process.env.PUBLIC_URL + "/assets/logos/emanate.png" },
      { name: "PEOS", url: "/token/peos", tokenName: "PEOS", imageName: process.env.PUBLIC_URL + "/assets/logos/peos.png" },
      { name: "BOID", url: "/token/boid", tokenName: "BOID", imageName: process.env.PUBLIC_URL + "/assets/logos/NewBoidLogo.svg" },
    ].sort((a, b) => (a.name > b.name) ? 1 : -1);

    const tokenSearch = allTokens.filter(
      (token) => {
        return token.tokenName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      }
    )

    const tokenMenu = items => items.map((item) => {
      return (
        <li className="st-side-nav__item" onKeyDown={this.onKeyPressed} onClick={visible ? this.toggleMenu : this.onKeyPressed} key={item.tokenName}>
          <NavLink to={item.url} exact activeClassName="active">
            <div className="st-side-nav__icon">
              <Image src={item.imageName} centered circular style={menuIconSize} />
            </div>
            <span> {item.tokenName} </span>
          </NavLink>
        </li>
      );
    });

    const mainMenuItems = [
      { text: 'Manage REX', src: '/rex' },
      //{ text: 'Statistics', src: '/rex-stats' },
      //{ text: 'Account', src: '/account' },
      //{ text: 'Swap Tokens', src: '/swap' },
      //{ text: 'Request Listing', src: '/request' },
      //{ text: 'About Staker', src: '/about' },
    ];

    const mainMenu = (items, close) => items.map((item) => {
      return (
        <li className="st-top-menu__item" key={item.text} hidden={!acctName} onClick={close}>
          <NavLink to={item.src} activeClassName="active">
            <span>{item.text}</span>
          </NavLink>
        </li>
      );
    });

    return (
      <div className={`st-main-wrapper ${visible ? 'st-main-wrapper--side-active' : ''}`}>
        <div className="st-header" id="header">
          <div className="st-header__left">
            <Image as={Link} to="/" src={process.env.PUBLIC_URL + "/assets/images/staker_logo.svg"} />

            {networks && (
              <div className="st-network-wrap">
                <Image src={networks[value].image.src} style={{ width: '25px', height: '25px', borderRadius: '100px' }} />
                <Dropdown
                  pointing="top left"
                  header="Select A Network"
                  className="icon"
                  inline
                  multiple
                  options={networks}
                  value={value}
                  onChange={this.handleChange}
                />
              </div>
            )}

            <ul className="st-top-menu">
              <li className="st-top-menu__item" key="View Account">
                <NavLink to="/" activeClassName={window.location.href.indexOf("token") > -1 ? 'active' : ''}>
                  <span>Stake Tokens</span>
                </NavLink>
              </li>
              {mainMenu(mainMenuItems, null)}
            </ul>
          </div>
          <div className="st-header__right">
            <LoginAction themeHandler={this.themeHandler} theme={theme} />
            <div
              className={`st-burger ${visible ? 'st-burger--active' : ''}`}
              onClick={this.toggleMenu}
              onKeyDown={this.toggleMenu}
            >
              <div className="st-burger__item" />
              <div className="st-burger__item" />
              <div className="st-burger__item" />
            </div>
          </div>
        </div>

        <div className="st-main-body-wrapper">
          <div
            visible={this.state.visible.toString()}
            className="st-main-sidebar"
          >
            <Button className="st-main-sidebar__toggle" onClick={this.toggleMenu}>
              <Image src={process.env.PUBLIC_URL + "/assets/images/chevron-blue-right.svg"} />
            </Button>

            <div className="mobile-menu-bar">
              <Dropdown
                pointing="top left"
                header="Select A Network"
                className="icon"
                placeholder={<Image src={networks && networks[value].image.src} style={{ height: '25px', borderRadius: '100px' }} />}
                inline
                multiple
                options={networks}
                value={value}
                onChange={this.handleChange}
              />
              <button
                basic
                circular
                icon
                className="st-button st-button--theme-switcher"
                type="button"
                onClick={() => {
                  this.themeHandler(theme == 'dark' ? 'light' : 'dark')
                }}
              >
                <Image className="theme-switcher" src={theme == 'dark' ? '../assets/theme/dark-on.svg' : '../assets/theme/dark-off.svg'} width="22px" />
              </button>
            </div>

            <ul className="st-top-menu">
              <li className="st-top-menu__item" key="View Account">
                <NavLink to="/" activeClassName={window.location.href.indexOf("token") > -1 ? 'active' : ''} onClick={visible && this.toggleMenu}>
                  <span>Stake Tokens</span>
                </NavLink>
              </li>
              {mainMenu(mainMenuItems, visible && this.toggleMenu)}
            </ul>

            <div className="st-main-sidebar__search">
              {visible && (
                <label className="st-input-wrap">
                  <input className="st-input" type="text" placeholder=" " onChange={this.updateSearch.bind(this)} value={this.state.search} />
                  <span className="st-input-label">SEARCH TOKEN</span>
                </label>
              )}
            </div>

            <ul className="st-side-nav">
              {tokenMenu(tokenSearch)}
            </ul>
          </div>
          <div className="st-main-body">
            <Header />
            {/*<Rotator />*/}
            <Switch>
              <Route exact path="/token/eos" render={() => <Eosio content={Eosio} TokenPrices={getTokenPrice(tokenPrices, "eos")} rexPrice={getTokenPrice(tokenPrices, "rex")} />} />
              <Route exact path="/rex" render={() => <EosRex content={EosRex} TokenPrices={getTokenPrice(tokenPrices, "eos")} RexPrice={getTokenPrice(tokenPrices, "rex")} />} />
              <Route path="/token/boid" render={() => <Boid content={Boid} TokenPrices={getTokenPrice(tokenPrices, "boid")} />} />
              <Route path="/token/seed" render={() => <Seed content={Seed} TokenPrices={getTokenPrice(tokenPrices, "parsl")} />} />
              <Route path="/token/efx" render={() => <Efx content={Efx} TokenPrices={getTokenPrice(tokenPrices, "effect-ai")} />} />
              <Route path="/token/emt" render={() => <Emt content={Emt} TokenPrices={getTokenPrice(tokenPrices, "emanate")} />} />
              <Route path="/token/peos" render={() => <Peos content={Peos} TokenPrices={getTokenPrice(tokenPrices, "peos")} />} />
              <Route exact path="/request" component={Request} />

              <Route exact component={Error} />
            </Switch>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const tokenPrices = state.prices.getIn(['prices', 'data'], List()).toJS();
    return {
      acctName,
      tokenPrices,
    };
  },
  dispatch => ({
    getMessages: () => {
      dispatch(walletActions.getMessagesRoutine());
    },
    getTokenPrices: (args) => {
      dispatch(priceActions.getTokenPricesRoutine(args));
    },
    signInToWallet: (args) => {
      dispatch(walletActions.signInToWalletRoutine(args));
    },
    signOutOfWallet: (args) => {
      dispatch(walletActions.signOutOfWalletRoutine(args));
    },
    getAllBalances: (args) => {
      dispatch(walletActions.getAccountBalancesRoutine(args));
    },
    claimAirHodl: (args) => {
      dispatch(walletActions.claimAirHodlRoutine(args));
    },
  })
)(MainBody)));
