import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Image, Accordion, Icon } from 'semantic-ui-react';
import ContentLoader from '../../components/loader'

import * as emtActions from './emtActions';
import EmtBody from './emtBody';


class EfxData extends Component {
  state = { activeIndex: false }

  handleClick = () => {
    this.setState(prevState => ({
      activeIndex: !prevState.activeIndex
    }));
  }

  render() {
    const { emtBal, presale, TokenPrices, growth } = this.props;
    const { activeIndex } = this.state;

    const tokenDetails = {
      title: 'EMANATE',
      tagline: 'Emanate: Audio Exchange Protocol',
      about: 'Emanate is for artists, by artists. A technology network and music community designed to help lift the quality of your work.',
      links: [
        //remove not in use
        { url: 'https://emanate.live', icon: process.env.PUBLIC_URL + '/assets/images/social/www.svg', name: 'website', class: 'social-link' },
        { url: 'https://t.me/emanateofficial', icon: process.env.PUBLIC_URL + '/assets/images/social/telegram.svg', name: 'telegram', class: 'social-link' },
        { url: 'https://twitter.com/EmanateOfficial', icon: process.env.PUBLIC_URL + '/assets/images/social/twitter.svg', name: 'twitter', class: 'social-link' },
        { url: 'https://facebook.com/emanateofficial', icon: process.env.PUBLIC_URL + '/assets/images/social/facebook.svg', name: 'facebook', class: 'social-link' },
        { url: 'https://medium.com/emanate-live', icon: process.env.PUBLIC_URL + '/assets/images/social/medium.svg', name: 'medium', class: 'social-link' },
      ]
    }

    const socialLinks = items => items.map((item, id) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <a key={id} href={item.url} target="_blank" rel="noopener noreferrer">
          <Image src={item.icon} alt={item.name} className={item.class} />
        </a>
      );
    });

    return (
      <div className="st-main-body-inner">
        <div className="row">
          <div className="col col-md-4 st-card-col">
            <div className="st-card st-card__minheight">
              <h5 className="st-card__headline">{tokenDetails.tagline}</h5>
              <div className="st-card__token-info">
                <Image src={process.env.PUBLIC_URL + "/assets/logos/emanate.png"} alt="emt" /> <span className="st-card__token-name">{tokenDetails.title}</span>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__price-nob">
                  <h5>Price</h5>
                  <p>${TokenPrices ? TokenPrices.price : '0.00'}</p>
                </div>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__split-min">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="min" />
                  <div>
                    <h5>MIN 24H</h5>
                    <p>${TokenPrices ? TokenPrices.low : '0.00'}</p>
                  </div>
                </div>
                <div className="st-card__split-max">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="max" />
                  <div>
                    <h5>MAX 24H</h5>
                    <p>${TokenPrices ? TokenPrices.high : '0.00'}</p>
                  </div>
                </div>
              </div>
              <div className="st-card__description">
                {tokenDetails.about}
              </div>
              <div className="st-card__socials">
                {socialLinks(tokenDetails.links)}
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">BALANCES</h5>
              <Image className="st-resources__icon" src={process.env.PUBLIC_URL + "/assets/images/dropp-arrow-icon.svg"} alt="min" />
              <h4 className="st-resources__title">AVAILABLE</h4>
              <p className="st-resources__balance">{emtBal ? ` ${parseFloat(parseFloat(emtBal) - (parseFloat(presale) + parseFloat(growth))).toFixed(4)} EMT` : '0.0000 EMT'}</p>
              <ul className="st-resources__info list-reset">
                <li>
                  <h5>TOTAL BALANCE</h5>
                  <p>{emtBal ? emtBal : "0.0000 EMT"}</p>
                </li>
                <li>
                  <h5>PRE-SALE BONUS</h5>
                  <p>{presale ? presale : "0.0000 EMT"}</p>
                </li>
                <li>
                  <h5>GROWTH BONUS</h5>
                  <p>{growth ? growth : "0.0000 EMT"}</p>
                </li>
              </ul>
              <ContentLoader size="massive" />
            </div>
          </div>

          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">Details</h5>
              <h4 className="st-resources__title">Rewards</h4>
              <p>Growth pool currently return 0.5% weekly interest</p>
              <div className="st-card__return">
                <div className="divider-horizontal" />
              </div>
              <h4 className="st-resources__title mt-3">Growth Pool</h4>
              <p>To help grow Emanate network, you can lock your EMT into the Emanate Growth Pool.</p>
              <Accordion>
                <Accordion.Title
                  active={activeIndex}
                  index={0}
                  onClick={this.handleClick}
                  style={{ color: 'var(--blue)', fontWeight: 'bolder' }}
                >
                  <Icon name="dropdown" />
                  The growth pool helps the network by:
                </Accordion.Title>
                <Accordion.Content active={activeIndex}>
                  <p>Allowing new users to trial Emanate in a frictionless way (without staking EMT)</p>
                  <p>Allow new artists to earn their own EMT stake without prior blockchain experience</p>
                  <p>Preserving EMT tokenomics when these new users join</p>
                  <p>Rewarding long-term supporters of Emanate network</p>
                </Accordion.Content>
              </Accordion>
            </div>
          </div>
        </div>
        <EmtBody />
      </div>
    )
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const emtBal = state.data.getIn(['accountInfo', 'emtRows', 'rows', 0, 'balance'], 0);
    const mnxBal = state.data.getIn(['accountInfo', 'emtRows', 'mnx', 'rows', 0, 'balance'], 0);
    const presale = state.data.getIn(['accountInfo', 'emtRows', 'presale', 'rows', 0, 'balance'], 0);
    const growth = state.data.getIn(['accountInfo', 'emtRows', 'staked', 'rows', 0, 'balance'], 0);

    return {
      acctName,
      emtBal,
      mnxBal,
      presale,
      growth
    };
  },
  dispatch => ({
    getefxBalance: (args) => {
      dispatch(emtActions.getEmtBalanceRoutine(args));
    },
  })
)(EfxData));