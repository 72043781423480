import {fromJS} from 'immutable';
import * as walletActions from './walletActions';


const defaultState = fromJS({
  connected: false,
  loaded: false,
  identity: null,
  connection: null,
  account: null,
  transaction_message: {},
  history: {}
});

export default function reducer(state = defaultState, action) {
  const {payload} = action;

  switch (action.type) {
    case walletActions.getMessagesRoutine.TRIGGER: {
      return state;
    }

    case walletActions.getMessagesRoutine.SUCCESS: {
      const newState = state.set('messages', fromJS(payload));
      return newState;
    }

    case walletActions.getMessagesRoutine.FAILURE: {
      return state;
    }

    case walletActions.connectToWalletRoutine.TRIGGER: {
      return state;
    }

    case walletActions.connectToWalletRoutine.SUCCESS: {
      const newState = state.set('connection', fromJS(payload));
      return newState;
    }

    case walletActions.connectToWalletRoutine.FAILURE: {
      console.error('Failed to connect to Wallet');
      return state;
    }

    //Yolo TokenSwap
    case walletActions.tokenSwapRoutine.TRIGGER: {
      return state;
    }
    //Yolo TokenSwap
    case walletActions.tokenSwapRoutine.SUCCESS: {
      console.log(payload)
      const yoloIframe = document.getElementById('yolo');
      yoloIframe && yoloIframe.contentWindow.postMessage(JSON.stringify({
        action: "transaction",
        data: {
          broadcast: true,
          transaction_id: payload.id,
        }
      }), "https://yoloswap.com/widget");
      return state;
    }
    //Yolo TokenSwap
    case walletActions.tokenSwapRoutine.FAILURE: {
      console.log(payload)
      const yoloIframe = document.getElementById('yolo');
      yoloIframe && yoloIframe.contentWindow.postMessage(JSON.stringify({
        action: "transaction",
        data: {
          code: 500,
          error: {
            code: 3090004,
            details: [{
              message: payload.message
            }],
            name: 'error_message',
            what: payload.message
          },
          message: payload.message
        }
      }), "https://yoloswap.com/widget");
      return state;
    }

    case walletActions.signInToWalletRoutine.TRIGGER: {
      return state;
    }

    case walletActions.signInToWalletRoutine.SUCCESS: {
      console.log(payload);
      const tempState = state.set('identity', fromJS(payload));
      const account = payload.account_name;
      console.log('account', account);
      const newState = tempState.set('account', fromJS(account));
      console.log('newstate', newState);
      return newState;
    }

    case walletActions.signInToWalletRoutine.FAILURE: {
      return state;
    }

    case walletActions.signOutOfWalletRoutine.TRIGGER: {
      return state;
    }

    case walletActions.signOutOfWalletRoutine.SUCCESS: {
      const newState = fromJS({
        connected: false,
        loaded: false,
        identity: null,
        connection: null,
        account: null,
      });
      return newState;
    }

    case walletActions.signOutOfWalletRoutine.FAILURE: {
      return state;
    }

    case walletActions.getAccountBalancesRoutine.TRIGGER: {
      return state;
    }

    case walletActions.getAccountBalancesRoutine.SUCCESS: {
      const newState = state.set('accountInfo', fromJS(payload));
      return newState;
    }

    case walletActions.getAccountBalancesRoutine.FAILURE: {
      return state;
    }


    case walletActions.getTokenPricesRoutine.TRIGGER: {
      return state;
    }

    case walletActions.getTokenPricesRoutine.SUCCESS: {
      const newState = state.set('tokenPrices', fromJS(payload));
      return newState;
    }

    case walletActions.getTokenPricesRoutine.FAILURE: {
      return state;
    }


    case walletActions.dataLoadedRoutine.TRIGGER: {
      return state;
    }

    case walletActions.dataLoadedRoutine.SUCCESS: {
      const dataLoaded = state.set('loaded', true);
      return dataLoaded;
    }

    case walletActions.dataLoadedRoutine.FAILURE: {
      return state;
    }

    case walletActions.connectedRoutine.TRIGGER: {
      return state;
    }

    case walletActions.connectedRoutine.SUCCESS: {
      const connectChange = state.set('connected', true);
      return connectChange;
    }

    case walletActions.connectedRoutine.FAILURE: {
      return state;
    }

    case walletActions.accountHistoryRoutine.TRIGGER: {
      return state;
    }

    case walletActions.accountHistoryRoutine.SUCCESS: {
      console.log(payload);
      const newState = state.set('history', fromJS(payload));
      return newState;
    }

    case walletActions.accountHistoryRoutine.FAILURE: {
      return state;
    }

    default:
      return state;
  }
}