/* eslint-disable react/destructuring-assignment */
import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as walletActions from '../../../../wallet/walletActions';

class Borrow extends Component {
  constructor() {
    super();
    this.state = {
      rexBuyAmount: '',
      borrowFrom: 0,
      borrowTo: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  changeBorrow = (event) => {
    this.setState({
      borrowFrom: event.target.value,
    });
  }

  render() {
    const { acctName, borrowEos, rexFund } = this.props;
    const { rexBuyAmount, borrowFrom, borrowTo } = this.state;
    const rexDefault = '0.0000';

    return (
      <div className="st-pannel-inner st-card__lend">
        <div className="row d-flex justify-content-center">
          <div className="col col-12 col-md-6">
            <button
              type="button"
              className={`st-button mgbot-sm ${borrowFrom==0 ? "st-button--blue" : ""}`}
              value={0}
              onClick={this.changeBorrow}
            >
              RENT CPU
            </button>
          </div>
          <div className="col col-12 col-md-6">
            <button
              type="button"
              className={`st-button ${borrowFrom==1 ? 'st-button--blue' : ''}`}
              value={1}
              onClick={this.changeBorrow}
            >
              RENT NET
            </button>
          </div>
          <div className="col col-12 col-md-12">
            <label className="st-input-wrap">
              <input
                className="st-input"
                type="text"
                id="borrowTo"
                placeholder=" "
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label">Receiver Account Name</span>
            </label>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col col-md-6 text-center">
            <p className="st-card__bold">EOS TO BORROW</p>
            <input
              className="st-input st-input--number"
              type="number"
              id="rexBuyAmount"
              placeholder={rexDefault}
              value={rexBuyAmount}
              onChange={this.onChangeInput}
              autoComplete="off"
            />
            <p className="st-card__bold font-s">{'AVAILABLE FUND: ' + rexFund}</p>
          </div>
        </div>

        <button
          className="st-button st-button--large st-button--purple"
          type="button"
          size="huge"
          disabled={!acctName}
          onClick={() => {
            borrowEos({
              method: borrowFrom==0 ? 'rentcpu' : 'rentnet',
              data: {
                from: acctName,
                receiver: borrowTo ? borrowTo : acctName,
                loan_payment: parseFloat(rexBuyAmount).toFixed(4) + ' TLOS',
                loan_fund: '0.0000 TLOS',
              },
              acctName
            });
          }}> {borrowFrom==0 ? 'BORROW CPU' : 'BORROW NET'}
        </button>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'rex_balance'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);
    const rexPrice = state.prices.getIn(['prices', 'data', 'rex', 'data', 'price'], 0);

    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'total_resources', 'net_weight'], 0);

    return {
      acctName,
      eosBal,
      rexBal,
      rexFund,
      rexPrice,
      eosCpu,
      eosNet
    };
  },
  dispatch => ({
    borrowEos: id => dispatch(walletActions.borrowRexRoutine({id}))
  }),
)(Borrow));