import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Image, Progress } from 'semantic-ui-react';
import EosioBody from './eosioBody';
import ContentLoader from '../../components/loader';


class EosioData extends Component {
  render() {
    const { acctBal, TokenPrices, refundDate, eosCpu, eosNet, eosCpuTotal, eosNetTotal, eosRamTotal, netKbMax, netKbUsed, ramKbUsed, ramKbMax, refundTotal, cpuSecUsed, cpuSecMax, totalRexEos } = this.props;
    const tokenDetails = {
      title: 'EOS',
      tagline: 'EOSIO: Blockchain software architecture',
      about: 'The most powerful infrastructure for decentralized applications.',
      links: [
        //remove not in use
        { url: 'https://eos.io', icon: '../assets/images/social/www.svg', name: 'website', class: 'social-link' },
        { url: 'https://medium.com/eosio', icon: '../assets/images/social/medium.svg', name: 'medium', class: 'social-link' },
        { url: 'https://t.me/EOSproject', icon: '../assets/images/social/telegram.svg', name: 'telegram', class: 'social-link' },
        { url: 'https://twitter.com/block_one_', icon: '../assets/images/social/twitter.svg', name: 'twitter', class: 'social-link' },
        { url: 'https://facebook.com/blockoneofficial', icon: '../assets/images/social/facebook.svg', name: 'facebook', class: 'social-link' },
      ]
    }

    const socialLinks = items => items.map((item) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <a href={item.url} key={item.name} target="_blank" rel="noopener noreferrer">
          <Image src={item.icon} alt={item.name} className={item.class} />
        </a>
      );
    });
    return (
      <div className="st-main-body-inner">
        <div className="row">
          <div className="col col-12 col-md-12 col-lg-4 st-card-col">
            <div className="st-card st-card__minheight">
              <h5 className="st-card__headline">{tokenDetails.tagline}</h5>
              <div className="st-card__token-info">
                <Image src={process.env.PUBLIC_URL + "/assets/images/eos-logo.svg"} alt="eos" /> <span className="st-card__token-name">{tokenDetails.title}</span>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__price">
                  <h5>Price</h5>
                  <p>${TokenPrices ? TokenPrices.price.toFixed(2) : '0.00'}</p>
                </div>
                <div className="st-card__price-min">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="min" />
                  <div>
                    <h5>MIN 24H</h5>
                    <p>${TokenPrices ? TokenPrices.low.toFixed(2) : '0.00'}</p>
                  </div>
                </div>
                <div className="st-card__price-max">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="max" />
                  <div>
                    <h5>MAX 24H</h5>
                    <p>${TokenPrices ? TokenPrices.high.toFixed(2) : '0.00'}</p>
                  </div>
                </div>
              </div>
              <div className="st-card__description">
                {tokenDetails.about}
              </div>
              <div className="st-card__socials">
                {socialLinks(tokenDetails.links)}
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-12 col-lg-8 st-card-col">
            <div className="st-card st-resources">
              <div className="st-resources__side">
                <h5 className="st-card__headline">RESOURCES</h5>
                <Image className="st-resources__icon" src={process.env.PUBLIC_URL + "/assets/images/dropp-arrow-icon.svg"} alt="min" />
                <h4 className="st-resources__title">AVAILABLE</h4>
                <h3 className="st-resources__balance">{acctBal}</h3>
                <ul className="st-resources__info list-reset">
                  <li>
                    <h5>REFUNDING</h5>
                    <p>{refundTotal + ' EOS'}
                      <br />
                      <small hidden={!refundDate}><Moment date={refundDate} /></small>
                    </p>
                  </li>
                  <li>
                    <h5>CPU STAKED</h5>
                    <p>{eosCpu ? eosCpu : '0.0000 EOS'}</p>
                  </li>
                  <li>
                    <h5>NET STAKED</h5>
                    <p>{eosNet ? eosNet : '0.0000 EOS'}</p>
                  </li>
                  <li>
                    <h5>TOTAL REX</h5>
                    <p>{totalRexEos ? totalRexEos + ' EOS' : '0.0000 EOS'}</p>
                  </li>
                </ul>
              </div>
              <div className="st-resources__main">
                <ul className="st-indicators list-reset">
                  <li className="st-indicator">
                    <div className="st-indicator__left">
                      <Image className="st-indicator__icon" src={process.env.PUBLIC_URL + "/assets/images/cpu-icon.svg"} alt="CPU" />
                    </div>
                    <div className="st-indicator__right">
                      <h4 className="st-indicator__title">STAKED | CPU</h4>
                      <Progress percent={eosCpuTotal} active />
                      <p className="st-indicator__info">{cpuSecUsed} / {cpuSecMax}</p>
                    </div>
                  </li>
                  <li className="st-indicator">
                    <div className="st-indicator__left">
                      <Image className="st-indicator__icon" src={process.env.PUBLIC_URL + "/assets/images/net-icon.svg"} alt="NET" />
                    </div>
                    <div className="st-indicator__right">
                      <h4 className="st-indicator__title">STAKED | NET</h4>
                      <Progress percent={eosNetTotal} active />
                      <p className="st-indicator__info">{netKbUsed}/ {netKbMax}</p>
                    </div>
                  </li>
                  <li className="st-indicator">
                    <div className="st-indicator__left">
                      <Image className="st-indicator__icon" src={process.env.PUBLIC_URL + "/assets/images/ram-icon.svg"} alt="RAM" />
                    </div>
                    <div className="st-indicator__right">
                      <h4 className="st-indicator__title">RAM {isNaN(eosRamTotal) ? 0 : eosRamTotal}%</h4>
                      <Progress percent={eosRamTotal} active indicating />
                      <p className="st-indicator__info">{ramKbUsed}/ {ramKbMax}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <ContentLoader size="massive" />
            </div>
          </div>
        </div>
        <EosioBody />
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const acctBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);

    const refundDate = state.data.getIn(['accountInfo', 'eosRows', 'refund', 'rows', 0, 'request_time'], 0);
    const refundCpu = state.data.getIn(['accountInfo', 'eosRows', 'refund', 'rows', 0, 'cpu_amount'], 0);
    const refundNet = state.data.getIn(['accountInfo', 'eosRows', 'refund', 'rows', 0, 'net_amount'], 0);
    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'net_weight'], 0);
    const eosCpuUsed = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'used'], 0);
    const eosCpuMax = state.data.getIn(['accountInfo', 'accountRows', 'cpu_limit', 'max'], 0);
    const eosNetUsed = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'used'], 0);
    const eosNetMax = state.data.getIn(['accountInfo', 'accountRows', 'net_limit', 'max'], 0);
    const eosRamUsed = state.data.getIn(['accountInfo', 'accountRows', 'ram_usage'], 0);
    const eosRamMax = state.data.getIn(['accountInfo', 'accountRows', 'ram_quota'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'vote_stake'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);
    const totalRexEos = (parseFloat(rexFund) + parseFloat(rexBal)).toFixed(4);
    const refundTotal = (parseInt(refundCpu) + parseInt(refundNet)).toFixed(4);

    const eosCpuTotal = parseFloat(eosCpuUsed / eosCpuMax * 100).toFixed(2);
    const eosNetTotal = parseFloat(eosNetUsed / eosNetMax * 100).toFixed(2);
    const eosRamTotal = Math.round(eosRamUsed / eosRamMax * 100).toFixed(2);

    function formatBytes(bytes) {
      if (bytes < 1024) return bytes + ' Bytes';
      else if (bytes < 1048576) return (bytes / 1024).toFixed(2) + ' KB';
      else if (bytes < 1073741824) return (bytes / 1048576).toFixed(2) + ' MB';
      else return (bytes / 1073741824).toFixed(2) + ' GB';
    }

    function formatCpu(secs) {
      if (secs < 1000) return secs + ' µs';
      else if (secs < 1000000) return (secs / 1000).toFixed(2) + ' ms';
      else if (secs < 1000000000) return (secs / 1000000).toFixed(2) + ' s';
      else if (secs < 10000000000) return (secs / 1000000000).toFixed(2) + ' min';
      else if (secs < 100000000000) return (secs / 10000000000).toFixed(2) + ' hour';
      else if (secs < 1000000000000) return (secs / 100000000000).toFixed(2) + ' days';
      else return (secs / 10000000000000).toFixed(2) + ' weeks';
    }

    const netKbUsed = formatBytes(eosNetUsed);
    const netKbMax = formatBytes(eosNetMax);
    const ramKbUsed = formatBytes(eosRamUsed);
    const ramKbMax = formatBytes(eosRamMax);
    const cpuSecUsed = formatCpu(eosCpuUsed);
    const cpuSecMax = formatCpu(eosCpuMax);

    return {
      acctName,
      acctBal,
      refundDate,
      eosCpu,
      eosNet,
      eosCpuUsed,
      eosCpuMax,
      eosCpuTotal,
      eosNetUsed,
      eosNetMax,
      eosNetTotal,
      eosRamUsed,
      eosRamMax,
      eosRamTotal,
      netKbMax,
      netKbUsed,
      ramKbUsed,
      ramKbMax,
      refundTotal,
      cpuSecUsed,
      cpuSecMax,
      totalRexEos
    };
  },
)(EosioData));