import {put, takeLatest} from 'redux-saga/effects';
import * as peosActions from './peosActions';
import * as walletActions from '../../wallet/walletActions';
import * as WalletClientApi from '../../wallet/walletClientApi';
import { getPeosData } from '../../accountData/fetchClientApi';
import {showProcessAlert, showSuccessAlert, showErrorAlert} from '../../alerts/showAlert';

export const getPeosWatches = [
  getPeosStakeWatch,
  getPeosUnStakeWatch,
  getPeosRefundWatch,
  getPeosClaimWatch
];

export function* getPeosStakeWatch() {
  yield takeLatest(peosActions.stakePeosRoutine.TRIGGER, stakePeos);
}

export function* getPeosUnStakeWatch() {
  yield takeLatest(peosActions.unStakePeosRoutine.TRIGGER, unstakePeos);
}

export function* getPeosRefundWatch() {
  yield takeLatest(peosActions.refundPeosRoutine.TRIGGER, claimRefund);
}

export function* getPeosClaimWatch() {
  yield takeLatest(peosActions.claimPeosRoutine.TRIGGER, claimDiv);
}


function* stakePeos({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'thepeostoken',
    method: 'stake',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(peosActions.stakePeosRoutine.success(serverResponse));
    const getBalances = yield getPeosData({account_name: payload.id.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(peosActions.stakePeosRoutine.failure(serverResponse));
  }
}

function* unstakePeos({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload.id);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'thepeostoken',
    method: 'unstake',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(peosActions.unStakePeosRoutine.success(serverResponse));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(peosActions.unStakePeosRoutine.failure(serverResponse));
  }
}

function* claimRefund({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'thepeostoken',
    method: 'refund',
    data: payload,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(peosActions.refundPeosRoutine.success(serverResponse));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(peosActions.refundPeosRoutine.failure(serverResponse));
  }
}

function* claimDiv({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'thepeostoken',
    method: 'realizediv',
    data: payload,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(peosActions.claimPeosRoutine.success(serverResponse));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(peosActions.claimPeosRoutine.failure(serverResponse));
  }
}