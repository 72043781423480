import {fromJS} from 'immutable';
import * as priceActions from './pricesActions';

const defaultState = fromJS({
});

export default function reducer(state = defaultState, action) {
  const {payload} = action;

  switch (action.type) {
    case priceActions.getTokenPricesRoutine.TRIGGER: {
      return state;
    }

    case priceActions.getTokenPricesRoutine.SUCCESS: {
      const newState = state.set('prices', fromJS(payload));
      return newState;
    }

    case priceActions.getTokenPricesRoutine.FAILURE:
      return state;

    default:
      return state;
  }
}