import Swal from 'sweetalert2';

export const showCustomAlert = (message) => {
  Swal.fire({
    position: 'top-end',
    text: message,
    timer: 8000,
    toast: true,
    animation: true,
    customClass: {
      popup: 'container-class',
    },
    showConfirmButton: false,
    showCancelButton: false,
  });
};

export const showLoader = () => {
  Swal.fire({
    position: 'top-end',
    text: 'Connecting to wallet, please stand by...',
    timer: 4500,
    toast: true,
    animation: true,
    customClass: {
      popup: 'swal2-connect',
    },
    showConfirmButton: false,
    showCancelButton: false,
  });
};

export const showConnected = (name) => {
  Swal.fire({
    position: 'top-end',
    text: 'Connected With ' + name,
    timer: 4500,
    toast: true,
    animation: true,
    customClass: {
      popup: 'swal2-connected',
    },
    showConfirmButton: false,
    showCancelButton: false,
  });
};

export const showRequest = (message) => {
  Swal.fire({
    position: 'top-end',
    type: 'info',
    title: 'Requesting Wallet Identity!',
    toast: true,
    animation: true,
    text: message,
    timer: 5000,
    customClass: {
      popup: 'swal2-connected',
    },
  });
};

export const showProcessAlert = (message) => {
  Swal.fire({
    position: 'top-end',
    text: message,
    timer: 8000,
    toast: true,
    animation: true,
    customClass: {
      popup: 'swal2-connect',
    },
    showConfirmButton: false,
    showCancelButton: false,
  });
};

export const showSuccessAlert = (message, transaction) => {
  Swal.fire({
    position: 'top-end',
    text: message,
    footer: '<a href="https://bloks.io/transaction/'+transaction+'" target="_blank">' + transaction + '</a>',
    //timer: 10000,
    animation: true,
    toast: true,
    customClass: {
      popup: 'swal2-transaction',
    },
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
  });
};

export const showErrorAlert = (message) => {
  Swal.fire({
    position: 'top-end',
    text: message,
    timer: 2500,
    toast: true,
    animation: true,
    customClass: {
      popup: 'swal2-err',
    },
    showConfirmButton: false,
    showCancelButton: false,
  });
};