import {put, takeLatest} from 'redux-saga/effects';
import * as pxeosActions from './pixeosActions';
import * as walletActions from '../../wallet/walletActions';
import * as WalletClientApi from '../../wallet/walletClientApi';
import { getPixeosData } from '../../accountData/fetchClientApi';
import {showProcessAlert, showSuccessAlert, showErrorAlert} from '../../alerts/showAlert';

export const getPixeosWatches = [
  getPixeosStakeWatch,
  getPixeosUnStakeWatch
];

export function* getPixeosStakeWatch() {
  yield takeLatest(pxeosActions.stakePixeosRoutine.TRIGGER, stakePixeos);
}

export function* getPixeosUnStakeWatch() {
  yield takeLatest(pxeosActions.unStakePixeosRoutine.TRIGGER, unstakePixeos);
}


function* stakePixeos({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'pixeos1stake',
    method: 'stake',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(pxeosActions.stakePixeosRoutine.success(serverResponse));
    const getBalances = yield getPixeosData({account_name: payload.id.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(pxeosActions.stakePixeosRoutine.failure(serverResponse));
  }
}

function* unstakePixeos({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'pixeos1stake',
    method: 'unstake',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(pxeosActions.unStakePixeosRoutine.success(serverResponse));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(pxeosActions.unStakePixeosRoutine.failure(serverResponse));
  }
}