import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import * as peosAction from './peosActions';

class PixeosBody extends Component {
  constructor() {
    super();
    this.state = {
      stakeAmount: '',
      unstakeAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { acctName, stakePeos, unstakePeos} = this.props;
    const { stakeAmount, unstakeAmount } = this.state;
    const stakeDefault = '0.0000';

    return (
      <div className="row d-flex justify-content-end">
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">STAKE PEOS</h5>
            <label className="st-input-wrap" htmlFor="stakeAmount">
              <input
                disabled={!acctName}
                id="stakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={stakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">STAKE AMOUNT</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                console.log(stakeAmount);
                stakePeos({
                  owner: acctName,
                  quantity: parseFloat(stakeAmount).toFixed(4) + ' PEOS',
                });
              }}> {acctName ? 'Stake' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">UNSTAKE PEOS</h5>
            <Popup
              content="Refund Time: 3 Days"
              position="top right"
              trigger={
                <img className="st-card__popup" src="../assets/images/info-icon.svg" alt="info" />
              }
            />
            <label className="st-input-wrap" htmlFor="unstakeAmount">
              <input
                disabled={!acctName}
                id="unstakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={unstakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">UNSTAKE AMOUNT</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                console.log(unstakeAmount);
                unstakePeos({
                  owner: acctName,
                  quantity: parseFloat(unstakeAmount).toFixed(4) + ' PEOS',
                });
              }}> {acctName ? 'UNSTAKE' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);

    return {
      acctName,
    };
  },
  dispatch => ({
    stakePeos: id => dispatch(peosAction.stakePeosRoutine({id})),
    unstakePeos: id => dispatch(peosAction.unStakePeosRoutine({id}))
  })
)(PixeosBody));