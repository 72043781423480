import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import ContentLoader from '../../components/loader'

import * as efxActions from './efxActions';
import EfxBody from './efxBody';


class EfxData extends Component {
  render() {
    const { acctName, efxBal, nfxBal, efxStake, efxunstaking, TokenPrices, airdropEst, claimEfx } = this.props;
    const tokenDetails = {
      title: 'EFX',
      tagline: 'EFX: Decentralized Network for Artificial Intelligence',
      about: 'Effect.AI introduces an open, decentralized network called The Effect Network, that provides services in the AI market.',
      links: [
        //remove not in use
        { url: 'https://effect.ai/', icon: '../assets/images/social/www.svg', name: 'website', class: 'social-link'},
        { url: 'https://t.me/effectai', icon: '../assets/images/social/telegram.svg', name: 'telegram', class: 'social-link'},
        { url: 'https://twitter.com/effectaix', icon: '../assets/images/social/twitter.svg', name: 'twitter', class: 'social-link'},
        { url: 'https://www.facebook.com/effectai', icon: '../assets/images/social/facebook.svg', name: 'facebook', class: 'social-link'},
        { url: 'https://medium.com/effect-ai', icon: '../assets/images/social/medium.svg', name: 'facebook', class: 'social-link'},
      ]
    }

    const socialLinks = items => items.map((item, id) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <a key={id} href={item.url} target="_blank" rel="noopener noreferrer">
          <Image src={item.icon} alt={item.name} className={item.class} />
        </a>
      );
    });

    return (
      <div className="st-main-body-inner">
        <div className="row">
          <div className="col col-md-4 st-card-col">
            <div className="st-card st-card__minheight">
              <h5 className="st-card__headline">{tokenDetails.tagline}</h5>
              <div className="st-card__token-info">
                <Image src="../assets/logos/efx.png" alt="efx" /> <span className="st-card__token-name">{tokenDetails.title}</span>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__price-nob">
                  <h5>Price</h5>
                  <p>${TokenPrices ? TokenPrices.price : '0.00'}</p>
                </div>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__split-min">
                  <Image src="../../assets/images/min-arrow.svg" alt="min" />
                  <div>
                    <h5>MIN 24H</h5>
                    <p>${TokenPrices ? TokenPrices.low : '0.00'}</p>
                  </div>
                </div>
                <div className="st-card__split-max">
                  <Image src="../../assets/images/min-arrow.svg" alt="max" />
                  <div>
                    <h5>MAX 24H</h5>
                    <p>${TokenPrices ? TokenPrices.high : '0.00'}</p>
                  </div>
                </div>
              </div>
              <div className="st-card__description">
                {tokenDetails.about}
              </div>
              <div className="st-card__socials">
                {socialLinks(tokenDetails.links)}
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">BALANCES</h5>
              <Image className="st-resources__icon" src="../assets/images/dropp-arrow-icon.svg" alt="min" />
              <h4 className="st-resources__title">AVAILABLE</h4>
              <p className="st-resources__balance">{efxBal ? efxBal : '0.0000 EFX'}</p>
              <p className="st-resources__balance" style={{marginTop: '-20px'}}>{nfxBal ? nfxBal : '0.0000 NFX'}</p>
              <ul className="st-resources__info list-reset">
                <li>
                  <h5>STAKED</h5>
                  <p>{efxStake ? efxStake : "0.0000 EFX"}</p>
                </li>
                <li>
                  <h5>REFUNDING</h5>
                  <p>{efxunstaking ? efxunstaking : "0.0000 EFX"}</p>
                </li>
                {acctName && (
                  <li>
                    <h5>ESTIMATED RETURN</h5>
                    <p>{airdropEst ? airdropEst : '....'}</p>
                  </li>
                )}
              </ul>
              <button
                hidden={!acctName}
                type="button"
                className="st-button st-button--blue st-button--large"
                onClick={() => {
                  claimEfx({
                    owner: acctName
                  });
                }}> CLAIM
              </button>
              <ContentLoader size="massive" />
            </div>
          </div>

          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">Details</h5>
              {/**<h4 className="st-resources__title">Rewards</h4>
              <div className="st-card__return">
                <div className="divider-horizontal" />
              </div>
              */}
            </div>
          </div>
        </div>
        <EfxBody />
      </div>
    )
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const efxBal = state.data.getIn(['accountInfo', 'efxRows', 'rows', 0, 'balance'], 0);
    const nfxBal = state.data.getIn(['accountInfo', 'efxRows', 'rows', 1, 'balance'], 0);
    const efxStake = state.data.getIn(['accountInfo', 'efxRows', 'staked', 'rows', 0, 'amount'], 0);
    const efxunstaking = state.data.getIn(['accountInfo', 'efxRows', 'unstaking', 'rows', 0, 'amount'], 0);


    return {
      acctName,
      efxBal,
      nfxBal,
      efxStake,
      efxunstaking,
    };
  },
  dispatch => ({
    getefxBalance: (args) => {
      dispatch(efxActions.getEfxBalanceRoutine(args));
    },
    claimEfx: id => dispatch(efxActions.claimEfxRoutine({id})),
  })
)(EfxData));