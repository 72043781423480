import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { Image, Button } from 'semantic-ui-react';

import * as peosActions from './peosActions';
import PeosBody from './peosBody';
import ContentLoader from '../../components/loader';


class PixeosData extends Component {
  render() {
    const { acctName, stakedBal, TokenPrices, acctBal, tokenRefund, tokenRefundTime, refundPeos, totalDividends, claimDiv } = this.props;
    const tokenDetails = {
      title: 'PEOS',
      tagline: ' privacy on EOS',
      about: 'pEOS enables private and untraceable transactions on EOS. Leveraging ring signatures, ring confidential transactions and stealth addresses pEOS is truly private. It combines Monero\'s technology with EOS performance.',
      imageSrc: process.env.PUBLIC_URL + '/assets/logos/peos.png',
      tokeSymbal: 'PEOS',
      links: [
        { url: 'https://peos.one/', icon: process.env.PUBLIC_URL + '/assets/images/social/www.svg', name: 'website', class: 'social-link' },
        { url: 'https://medium.com/@pEOS_one', icon: process.env.PUBLIC_URL + '/assets/images/social/medium.svg', name: 'medium', class: 'social-link' },
        { url: 'https://t.me/pEOSone', icon: process.env.PUBLIC_URL + '/assets/images/social/telegram.svg', name: 'telegram', class: 'social-link' },
        { url: 'https://twitter.com/peos_one', icon: process.env.PUBLIC_URL + '/assets/images/social/twitter.svg', name: 'twitter', class: 'social-link' },
      ],

    };

    const socialLinks = items => items.map((item) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <a href={item.url} target="_blank" rel="noopener noreferrer" key={item.name}>
          <Image src={item.icon} alt={item.name} className={item.class} />
        </a>
      );
    });

    return (
      <div className="st-main-body-inner">
        <div className="row">
          <div className="col col-md-4 st-card-col">
            <div className="st-card st-card__minheight">
              <h5 className="st-card__headline">{tokenDetails.title}:{tokenDetails.tagline} </h5>
              <div className="st-card__token-info">
                <Image src={tokenDetails.imageSrc} alt={tokenDetails.title} /> <span className="st-card__token-name">{tokenDetails.title}</span>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__price-nob">
                  <h5>Price</h5>
                  <p>${TokenPrices ? TokenPrices.price : '0.00'}</p>
                </div>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__split-min">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="min" />
                  <div>
                    <h5>MIN 24H</h5>
                    <p>${TokenPrices ? TokenPrices.low : '0.00'}</p>
                  </div>
                </div>
                <div className="st-card__split-max">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="max" />
                  <div>
                    <h5>MAX 24H</h5>
                    <p>${TokenPrices ? TokenPrices.high : '0.00'}</p>
                  </div>
                </div>
              </div>
              <div className="st-card__description">
                {tokenDetails.about}
              </div>
              <div className="st-card__socials">
                {socialLinks(tokenDetails.links)}
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">BALANCES</h5>
              <Image className="st-resources__icon" src={process.env.PUBLIC_URL + "/assets/images/dropp-arrow-icon.svg"} alt="min" />
              <h4 className="st-resources__title">AVAILABLE</h4>
              <p className="st-resources__balance">{acctBal ? acctBal : acctBal.toFixed(4) + ' ' + tokenDetails.tokeSymbal}</p>
              <ul className="st-resources__info list-reset">
                <li>
                  <h5>STAKED</h5>
                  <p>{stakedBal ? stakedBal : stakedBal.toFixed(4) + ' ' + tokenDetails.tokeSymbal}</p>
                </li>
                <li>
                  <h5>Dividends</h5>
                  <p>{totalDividends.toFixed(4) + ' ' + tokenDetails.tokeSymbal}</p>
                </li>
                {parseFloat(tokenRefund) > 0 && (
                  <li>
                    <h5>Refunding</h5>
                    <p><Moment add={{ days: 3 }} unix>{tokenRefundTime}</Moment></p>
                  </li>
                )}
                <li>
                  <Button
                    hidden={!tokenRefund}
                    content="Claim Refund"
                    className="st-button st-button--blue st-button--large"
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      refundPeos({
                        owner: acctName,
                      });
                    }}
                  />
                </li>
                <li>
                  <Button
                    hidden={!totalDividends}
                    content="Claim Dividends"
                    className="st-button st-button--blue st-button--large"
                    style={{ margin: 'auto' }}
                    onClick={() => {
                      claimDiv({
                        owner: acctName,
                      });
                    }}
                  />
                </li>
              </ul>
              <ContentLoader size="massive" />
            </div>
          </div>

          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">Details</h5>
              <h4 className="st-resources__title">Rewards</h4>
              <p>Staking rewards are dependent upon the number of private transactions that happen on the network.</p>
              <div className="st-card__return">
                <div className="divider-horizontal" />
              </div>
            </div>
          </div>
        </div>
        <PeosBody />
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const acctBal = state.data.getIn(['accountInfo', 'peosRows', 'rows', 0, 'balance'], 0);
    const stakedBal = state.data.getIn(['accountInfo', 'peosRows', 'staked', 'rows', 0, 'quantity'], 0);
    const tokenRefund = state.data.getIn(['accountInfo', 'peosRows', 'refunding', 'rows', 0, 'amount'], 0);
    const tokenRefundTime = state.data.getIn(['accountInfo', 'peosRows', 'refunding', 'rows', 0, 'request_time'], 0);
    const tokenDividends = state.data.getIn(['accountInfo', 'peosRows', 'dividends', 'rows', 0, 'totalDividendFrac'], 0);

    const userlastDiv = state.data.getIn(['accountInfo', 'peosRows', 'staked', 'rows', 0, 'lastDividendsFrac'], 0);
    const totalDividends = (tokenDividends - userlastDiv) * parseFloat(stakedBal).toFixed(4);


    return {
      acctName,
      acctBal,
      stakedBal,
      tokenRefund,
      tokenRefundTime,
      totalDividends
    };
  },
  dispatch => ({
    getPeosUnstake: (args) => {
      dispatch(peosActions.stakePeosRoutine(args));
    },
    refundPeos: (args) => {
      dispatch(peosActions.refundPeosRoutine(args));
    },
    claimDiv: (args) => {
      dispatch(peosActions.claimPeosRoutine(args));
    }
  })
)(PixeosData));