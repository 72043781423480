/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { List } from 'immutable';
import Moment from 'react-moment';
import { Menu, Tab, Table } from 'semantic-ui-react';
import UnlendContent from './unlend';
import * as walletActions from '../../../../wallet/walletActions';

class ManageOptions extends Component {
  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { acctName } = this.props;
    const cpuLoans = this.props.cpuLoans.toJS();
    const cpuLoansData = [];
    const netLoans = this.props.netLoans.toJS();
    const netLoansData = [];

    const panes = [
      {
        menuItem: (
          <Menu.Item key="unlend">
            <span>UNLEND</span>
            <h5>SELL REX</h5>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}><UnlendContent /></Tab.Pane>
      },
      {
        menuItem: (
          <Menu.Item key="borrowed">
            <span>BORROWED</span>
            <h5>CPU/NET ORDERS</h5>
          </Menu.Item>
        ),
        render: () => <Tab.Pane attached={false}>{displayLoans}</Tab.Pane>
      }
    ]

    cpuLoans.forEach((obj) => {
      obj.type = 'CPU';
      obj.num = obj.loan_num;
      obj.net = obj.receiver;
      obj.pay = obj.payment;
      obj.bal = obj.balance;
      obj.staked = obj.total_staked;
      obj.exp = obj.expiration;
      cpuLoansData.push(obj);
    });

    netLoans.forEach((obj) => {
      obj.type = 'NET';
      obj.num = obj.loan_num;
      obj.net = obj.receiver;
      obj.pay = obj.payment;
      obj.bal = obj.balance;
      obj.staked = obj.total_staked;
      obj.exp = obj.expiration;
      netLoansData.push(obj);
    });
    const renderLoans = items => items.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Table.Row key={item + index}>
          <Table.Cell>
            {item.type}
          </Table.Cell>
          <Table.Cell>
            {item.receiver}
          </Table.Cell>
          <Table.Cell>
            {item.payment}
          </Table.Cell>
          <Table.Cell>
            {item.balance}
          </Table.Cell>
          <Table.Cell>
            {item.total_staked}
          </Table.Cell>
          <Table.Cell style={{ backgroundColor: '#f1f1f1', color: '#000' }}>
            <Moment to={item.expiration} />
          </Table.Cell>
        </Table.Row>
      );
    });

    const displayLoans = (
      <div style={{ marginTop: '-60px', marginBottom: '5rem' }}>
        {cpuLoans.length > 0 || netLoans.length > 0 ? (
          <Table celled basic>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>TYPE</Table.HeaderCell>
                <Table.HeaderCell>RECEIVER</Table.HeaderCell>
                <Table.HeaderCell>LOAN FEE</Table.HeaderCell>
                <Table.HeaderCell>AUTO RENEW FUND</Table.HeaderCell>
                <Table.HeaderCell>BORROWED</Table.HeaderCell>
                <Table.HeaderCell>EXPIRE</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {renderLoans(cpuLoansData)}
              {renderLoans(netLoansData)}
            </Table.Body>
          </Table>
        ) : <p style={{ textAlign: 'center', marginTop: '10rem' }}>{!acctName ? "Login to view loans" : " Loading loans..."}</p>}
      </div>
    );


    return (
      <div>
        <Tab menu={{ secondary: true, pointing: true, widths: 2, size: 'massive' }} panes={panes} style={{ marginTop: '-45px' }} />
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'rex_balance'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);
    const rexLend = state.data.getIn(['accountInfo', 'rexRows', 'rexPool', 'rows', 0, 'total_lendable'], 0);
    const rexTotal = state.data.getIn(['accountInfo', 'rexRows', 'rexPool', 'rows', 0, 'total_rex'], 0);
    const cpuLoans = state.data.getIn(['accountInfo', 'eosRows', 'cpuloans', 'rows'], List());
    const netLoans = state.data.getIn(['accountInfo', 'eosRows', 'netloans', 'rows'], List());
    const rexPrice = rexLend ? (parseFloat(rexLend) / parseFloat(rexTotal)).toFixed(4) : '...';

    return {
      acctName,
      eosBal,
      rexBal,
      rexFund,
      rexPrice,
      cpuLoans,
      netLoans
    };
  },
  dispatch => ({
    stakeEos: id => dispatch(walletActions.stakeEosRoutine({ id })),
    unStakeEos: id => dispatch(walletActions.unStakeEosRoutine({ id })),
    borrowEos: id => dispatch(walletActions.borrowRexRoutine({ id }))
  })
)(ManageOptions));