import axios from 'axios';

export function* clientGetBrmBalance({ account_name }) {
  const resultObj = {};
  try {
    const brmBalance = yield axios.post('https://api.eosnewyork.io/v1/chain/get_table_rows', {
      json: 'true',
      scope: account_name,
      code: 'openbrmeos11',
      table: 'accounts',
    });
    resultObj.brmRows = brmBalance.data;
  } catch (e) {
    console.error('Error retrieving brmBalance', e);
  }

  try {
    const brmStakedBalance = yield axios.post('https://api.eosnewyork.io/v1/chain/get_table_rows', {
      json: 'true',
      scope: account_name,
      code: 'openbrmeos11',
      table: 'stake',
    });
    resultObj.brmStakedRows = brmStakedBalance.data;
  } catch (e) {
    console.error('Error retrieving brmStakedBalance', e);
  }

  return resultObj;
}