import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import * as efxAction from './efxActions';
import { showErrorAlert } from "../../alerts/showAlert";


class efxBody extends Component {
  constructor() {
    super();
    this.state = {
      stakeAmount: '',
      unStakeAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { acctName, stakeEfx, unstakeEfx, efxBal, efxStake } = this.props;
    const { stakeAmount, unStakeAmount } = this.state;
    const stakeDefault = '0.0000';

    return (
      <div className="row d-flex justify-content-end">
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">STAKE EFX</h5>
            <label className="st-input-wrap" htmlFor="stakeAmount">
              <input
                disabled={!acctName}
                id="stakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={stakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">STAKE AMOUNT</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                console.log(stakeAmount);
                console.log(efxStake == 0)
                parseFloat(parseFloat(stakeAmount).toFixed(4)) > parseFloat(parseFloat(efxBal).toFixed(4)) ? showErrorAlert(`You are trying to stake more then you have!`)
                  : stakeEfx({
                    newStake: efxStake == 0,
                    from: acctName,
                    to: 'efxstakepool',
                    quantity: parseFloat(stakeAmount).toFixed(4) + ' EFX',
                    memo: 'stake',
                  });
              }}> {acctName ? 'Stake' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">UNSTAKE EFX</h5>
            <Popup
              content="Refund Time: 5 Days"
              position="top right"
              trigger={
                <img className="st-card__popup" src="../assets/images/info-icon.svg" alt="info" />
              }
            />
            <label className="st-input-wrap" htmlFor="unStakeAmount">
              <input
                disabled={!acctName}
                id="unStakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={unStakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">UNSTAKE AMOUNT</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                parseFloat(parseFloat(unStakeAmount).toFixed(4)) > parseFloat(parseFloat(efxStake).toFixed(4)) ? showErrorAlert(`You are trying to unstake more then you have!`)
                  : unstakeEfx({
                    owner: acctName,
                    quantity: parseFloat(unStakeAmount).toFixed(4) + ' EFX',
                  });
              }}> {acctName ? 'UNSTAKE' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const efxBal = state.data.getIn(['accountInfo', 'efxRows', 'rows', 0, 'balance'], 0);
    const efxStake = state.data.getIn(['accountInfo', 'efxRows', 'staked', 'rows', 0, 'amount'], 0);

    return {
      acctName,
      efxBal,
      efxStake
    };
  },
  dispatch => ({
    stakeEfx: id => dispatch(efxAction.getEfxStakeRoutine({id})),
    unstakeEfx: id => dispatch(efxAction.getEfxUnstakeRoutine({id})),
  })
)(efxBody));