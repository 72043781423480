import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as seedAction from './seedActions';
import { showErrorAlert } from "../../alerts/showAlert";

class seedBody extends Component {
  constructor() {
    super();
    this.state = {
      stakeAmount: '',
      unStakeAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { acctName, stakeSeed, unStakeSeed, stakedBal, totalBal} = this.props;
    const { stakeAmount, unStakeAmount } = this.state;
    const stakeDefault = '0.0000';

    return (
      <div className="row d-flex justify-content-end">
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">STAKE SEED</h5>
            <label className="st-input-wrap" htmlFor="stakeAmount">
              <input
                disabled={!acctName}
                id="stakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={stakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">STAKE AMOUNT</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                console.log(stakeAmount, totalBal)
                parseFloat(parseFloat(stakeAmount).toFixed(4)) > parseFloat(parseFloat(totalBal).toFixed(4)) ? showErrorAlert(`You are trying to stake more then you have!`)
                  : stakeSeed({
                    owner: acctName,
                    quantity: parseFloat(stakeAmount).toFixed(4) + ' SEED',
                  });
              }}> {acctName ? 'Stake' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">UNSTAKE SEED</h5>
            <label className="st-input-wrap" htmlFor="unStakeAmount">
              <input
                disabled={!acctName}
                id="unStakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={unStakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">UNSTAKE AMOUNT</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                parseFloat(parseFloat(unStakeAmount).toFixed(4)) > parseFloat(parseFloat(stakedBal).toFixed(4)) ? showErrorAlert(`You are trying to stake more then you have!`)
                  : unStakeSeed({
                    owner: acctName,
                    quantity: parseFloat(unStakeAmount).toFixed(4) + ' SEED',
                  });
              }}> {acctName ? 'UNSTAKE' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const acctBal = state.data.getIn(['accountInfo', 'seedRows', 'rows', 0, 'balance'], 0);
    const stakedBal = state.data.getIn(['accountInfo', 'seedRows', 'staked', 'rows', 0, 'quantity'], 0);
    const totalBal = parseFloat(acctBal) - parseFloat(stakedBal);

    return {
      acctName,
      acctBal,
      stakedBal,
      totalBal
    };
  },
  dispatch => ({
    stakeSeed: id => dispatch(seedAction.getSeedStakeRoutine({id})),
    unStakeSeed: id => dispatch(seedAction.getSeedUnstakeRoutine({id})),
  })
)(seedBody));
