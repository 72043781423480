/* eslint-disable react/destructuring-assignment */
// Main Footer
import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Image, Icon, Header } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { version } from '../../../package.json';

class Footer extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = { theme: cookies.get('theme') };
  }

  render() {
    const { theme } = this.state;
    return (
      <div className="st-footer">

        <div className="d-flex justify-content-center" style={{ marginTop: '35px' }}>
          <div className="st-footer__item">
            <p className="st-footer__copy staker">
              <span>© Copyright {new Date().getFullYear()}</span>
              <NavLink to="/" exact>
                <Image src="../assets/images/staker_logo.svg" />
              </NavLink>
            </p>
          </div>
          <div className="st-footer__item">
            <p className="st-footer__copy">
              <span>Designed by</span>
              <a href="https://twitter.com/EOSPOTonline" target="_blank" rel="noopener noreferrer">
                {theme == 'dark' ? <Image src="../assets/images/eospot-logo-white.svg" /> : <Image src="../assets/images/eospot-logo.svg" />}
              </a>
            </p>
          </div>
        </div>


        <div className="d-flex justify-content-center" style={{ marginTop: '35px' }}>
          <div className="st-footer__item">
            <a href="https://t.me/stakerone" target="_blank" rel="noopener noreferrer">
              <Header as="h4" className="st-footer__copy" style={{ color: 'var(--blue)' }}>
                <Icon name="telegram plane" size="big" />
                Telegram
              </Header>
            </a>
          </div>
          <div className="st-footer__item">
            <a href="https://twitter.com/StakerOne" target="_blank" rel="noopener noreferrer">
              <Header as="h4" className="st-footer__copy" style={{ color: 'var(--blue)' }}>
                <Icon name="twitter" size="big" />
                Twitter
              </Header>
            </a>
          </div>
          <div className="st-footer__item">
            <a href="mailto:stakeronedapp@gmail.com" target="_blank" rel="noopener noreferrer">
              <Header as="h4" className="st-footer__copy" style={{ color: 'var(--blue)' }}>
                <Icon name="mail" size="big" />
                Email
              </Header>
            </a>
          </div>
        </div>

        <div className="d-flex justify-content-center" style={{ marginTop: '100px' }}>
          <div className="st-footer__item">
            <a href="https://forms.gle/X12LrELYTwyHPtpM8" target="_blank" rel="noopener noreferrer">
              <Header as="h4" className="st-footer__copy" style={{ color: '#45576b' }}>
                Version {version}
              </Header>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default withCookies(Footer);