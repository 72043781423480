import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import Moment from 'react-moment';
import SeedBody from './seedBody';
import * as seedAction from './seedActions';

import ContentLoader from '../../components/loader';


class SeedData extends Component {
  render() {
    const { TokenPrices, totalBal, stakedBal, refund, refundDate, acctName } = this.props;
    const tokenDetails = {
      title: 'SEED',
      tokeSymbal: 'SEED',
      tagline: 'SEED: Blockchain Cannabis Tracking & Supply Chain Technology',
      about: 'Parsl is a platform that utilises leading edge technologies to create a supply chain that is efficient, transparent and safe.',
      links: [
        //remove not in use
        { url: 'https://www.parsl.co/', icon: process.env.PUBLIC_URL + '/assets/images/social/www.svg', name: 'website', class: 'social-link' },
        { url: 'https://t.me/parslio', icon: process.env.PUBLIC_URL + '/assets/images/social/telegram.svg', name: 'telegram', class: 'social-link' },
        { url: 'https://twitter.com/Parslco', icon: process.env.PUBLIC_URL + '/assets/images/social/twitter.svg', name: 'twitter', class: 'social-link' },
        { url: 'https://www.facebook.com/ParslCo/', icon: process.env.PUBLIC_URL + '/assets/images/social/facebook.svg', name: 'facebook', class: 'social-link' },
      ]
    }

    const socialLinks = items => items.map((item, id) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <a key={id} href={item.url} target="_blank" rel="noopener noreferrer">
          <Image src={item.icon} alt={item.name} className={item.class} />
        </a>
      );
    });
    return (
      <div className="st-main-body-inner">
        <div className="row">
          <div className="col col-md-4 st-card-col">
            <div className="st-card st-card__minheight">
              <h5 className="st-card__headline">{tokenDetails.tagline}</h5>
              <div className="st-card__token-info">
                <Image src={process.env.PUBLIC_URL + "/assets/logos/seed.png"} alt="seed" /> <span className="st-card__token-name">{tokenDetails.title}</span>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__price-nob">
                  <h5>Price</h5>
                  <p>${TokenPrices ? TokenPrices.price : '0.00'}</p>
                </div>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__split-min">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="min" />
                  <div>
                    <h5>MIN 24H</h5>
                    <p>${TokenPrices ? TokenPrices.low : '0.00'}</p>
                  </div>
                </div>
                <div className="st-card__split-max">
                  <Image src={process.env.PUBLIC_URL + "/assets/images/min-arrow.svg"} alt="max" />
                  <div>
                    <h5>MAX 24H</h5>
                    <p>${TokenPrices ? TokenPrices.high : '0.00'}</p>
                  </div>
                </div>
              </div>
              <div className="st-card__description">
                {tokenDetails.about}
              </div>
              <div className="st-card__socials">
                {socialLinks(tokenDetails.links)}
              </div>
            </div>
          </div>

          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">BALANCES</h5>
              <Image className="st-resources__icon" src={process.env.PUBLIC_URL + "/assets/images/dropp-arrow-icon.svg"} alt="min" />
              <h4 className="st-resources__title">AVAILABLE</h4>
              <p className="st-resources__balance">{totalBal.toFixed(4)} {tokenDetails.tokeSymbal}</p>
              <ul className="st-resources__info list-reset">
                <li>
                  <h5>STAKED</h5>
                  <p>{stakedBal ? stakedBal : stakedBal.toFixed(4) + ' ' + tokenDetails.tokeSymbal}</p>
                </li>
                {refundDate > 0 && (
                  <li>
                    <h5>REFUNDING</h5>
                    <p>{refund}</p>
                  </li>
                )}
                {refundDate > 0 && (
                  <li>
                    <h5>REFUND DATE</h5>
                    <p><Moment unix>{refundDate + 10080 * 60}</Moment></p>
                  </li>
                )}
              </ul>
              <button
                type="button"
                className="st-button st-button--large st-button--claim st-button--blue"
                hidden={!acctName || !refundDate}
                onClick={() => {
                  refund({
                    owner: acctName,
                    sym: '4,SEED'
                  });
                }}
              >
                Claim Refund
              </button>
              <ContentLoader size="massive" />
            </div>
          </div>

          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">Details</h5>
              {/**<h4 className="st-resources__title">Rewards</h4>
              <div className="st-card__return">
                <div className="divider-horizontal" />
              </div>
              */}
            </div>
          </div>
        </div>
        <SeedBody />
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const acctBal = state.data.getIn(['accountInfo', 'seedRows', 'rows', 0, 'balance'], 0);
    const stakedBal = state.data.getIn(['accountInfo', 'seedRows', 'staked', 'rows', 0, 'quantity'], 0);
    const refund = state.data.getIn(['accountInfo', 'seedRows', 'refund', 'rows', 0, 'quantity'], 0);
    const refundDate = state.data.getIn(['accountInfo', 'seedRows', 'refund', 'rows', 0, 'updated_on'], 0);

    const totalBal = parseFloat(acctBal) - parseFloat(stakedBal);

    return {
      acctName,
      stakedBal,
      refund,
      refundDate,
      totalBal
    };
  },
  dispatch => ({
    refund: id => dispatch(seedAction.getSeedRefundRoutine({ id })),
  })
)(SeedData));
