import { put, takeLatest } from 'redux-saga/effects';
import { clientGetBrmBalance } from './brmClientApi';
import * as brmActions from './brmActions';
import { getBrmData } from '../../accountData/fetchClientApi';
import * as walletActions from '../../wallet/walletActions';
import * as walletClientApi from "../../wallet/walletClientApi";
import { showErrorAlert, showProcessAlert, showSuccessAlert } from "../../alerts/showAlert";


export const getBrmWatches = [
  getBrmBalanceWatch,
  getBrmStakeWatch,
  getBrmUnstakeWatch,
];


export function* getBrmBalanceWatch() {
  yield takeLatest(brmActions.getBrmBalanceRoutine.TRIGGER, brmBalance);
}
export function* getBrmStakeWatch() {
  yield takeLatest(brmActions.getBrmStakeRoutine.TRIGGER, brmStake);
}
export function* getBrmUnstakeWatch() {
  yield takeLatest(brmActions.getBrmUnstakeRoutine.TRIGGER, brmUnstake);
}

function* brmBalance({ payload }) {
  console.log(payload)
  const serverResponse = yield clientGetBrmBalance(payload);
  console.log('response', serverResponse);
  if (serverResponse) {
    yield put(brmActions.getBrmBalanceRoutine.success(serverResponse));
  } else {
    yield put(brmActions.getBrmBalanceRoutine.failure(serverResponse));
  }
}
function* brmStake({ payload }) {
  console.log(payload.id)
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'openbrmeos11',
    method: 'stake',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getBrmData({account_name: payload.id._stake_account});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(brmActions.getBrmStakeRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(brmActions.getBrmStakeRoutine.failure(serverResponse));
  }
}

function* brmUnstake({ payload }) {
  console.log(payload.id)
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload)
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'openbrmeos11',
    method: 'unstake',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getBrmData({account_name: payload.id._stake_account});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(brmActions.getBrmUnstakeRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(brmActions.getBrmUnstakeRoutine.failure(serverResponse));
  }
}