import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import * as emtAction from './emtActions';
import { showErrorAlert } from "../../alerts/showAlert";


class efxBody extends Component {
  constructor() {
    super();
    this.state = {
      stakeAmount: '',
      unStakeAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  }

  render() {
    const { acctName, stakeToken, unstakeToken, emtBal, growth } = this.props;
    const { stakeAmount, unStakeAmount } = this.state;
    const stakeDefault = '0.0000';

    return (
      <div className="row d-flex justify-content-end">
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">LOCK EMT</h5>
            <label className="st-input-wrap" htmlFor="stakeAmount">
              <input
                disabled={!acctName}
                id="stakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={stakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">Lock amount to add to pool</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                parseFloat(parseFloat(stakeAmount).toFixed(4)) > parseFloat(parseFloat(emtBal).toFixed(4)) ? showErrorAlert(`You are trying to lock more then you have!`)
                  : stakeToken({
                    owner: acctName,
                    balance: parseFloat(stakeAmount).toFixed(4) + ' EMT',
                  });
              }}> {acctName ? 'Lock' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
        <div className="col col-12 col-md-6 st-card-col">
          <div className="st-card">
            <h5 className="st-card__headline">UNLOCK EMT</h5>
            <Popup
              content="Refund Time: Instant"
              position="top right"
              trigger={
                <img className="st-card__popup" src="../assets/images/info-icon.svg" alt="info" />
              }
            />
            <label className="st-input-wrap" htmlFor="unStakeAmount">
              <input
                disabled={!acctName}
                id="unStakeAmount"
                className="st-input"
                type="number"
                min="0"
                placeholder={stakeDefault}
                value={unStakeAmount}
                onChange={this.onChangeInput}
                autoComplete="off"
              />
              <span className="st-input-label__amount">Un-lock Growth Pool EMT</span>
            </label>
            <button
              disabled={!acctName}
              type="button"
              className="st-button st-button--blue st-button--large"
              onClick={() => {
                parseFloat(parseFloat(unStakeAmount).toFixed(4)) > parseFloat(parseFloat(growth).toFixed(4)) ? showErrorAlert(`You are trying to unlock more then you have!`)
                  : unstakeToken({
                    owner: acctName,
                    balance: parseFloat(unStakeAmount).toFixed(4) + ' EMT',
                  });
              }}> {acctName ? 'Unlock' : '(Connect A Wallet)' }
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const emtBal = state.data.getIn(['accountInfo', 'emtRows', 'rows', 0, 'balance'], 0);
    const mnxBal = state.data.getIn(['accountInfo', 'emtRows', 'mnx', 'rows', 0, 'balance'], 0);
    const presale = state.data.getIn(['accountInfo', 'emtRows', 'presale', 'rows', 0, 'balance'], 0);
    const growth = state.data.getIn(['accountInfo', 'emtRows', 'staked', 'rows', 0, 'balance'], 0);

    return {
      acctName,
      emtBal,
      mnxBal,
      presale,
      growth
    };
  },
  dispatch => ({
    stakeToken: id => dispatch(emtAction.getEmtStakeRoutine({id})),
    unstakeToken: id => dispatch(emtAction.getEmtUnstakeRoutine({id})),
  })
)(efxBody));