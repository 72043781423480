import {put, takeLatest} from 'redux-saga/effects';
import * as trybeActions from './trybeActions';
import * as walletActions from '../../wallet/walletActions';
import * as WalletClientApi from '../../wallet/walletClientApi';
import { getTrybeData } from '../../accountData/fetchClientApi';
import {showProcessAlert, showErrorAlert, showSuccessAlert} from '../../alerts/showAlert';

export const getTrybeWatches = [
  getTrybeStakeWatch,
  getTrybeunStakeWatch,
  claimTrybeWatch
];

export function* getTrybeStakeWatch() {
  yield takeLatest(trybeActions.stakeTrybeRoutine.TRIGGER, stakeTrybe);
}

export function* getTrybeunStakeWatch() {
  yield takeLatest(trybeActions.unStakeTrybeRoutine.TRIGGER, unstakeTrybe);
}

export function* claimTrybeWatch() {
  yield takeLatest(trybeActions.claimTrybeRoutine.TRIGGER, claimTrybe);
}

function* stakeTrybe({payload}) {
  console.log(payload.owner)
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'trybenetwork',
    method: 'stake2',
    data: payload,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(trybeActions.stakeTrybeRoutine.success(serverResponse));
    const getBalances = yield getTrybeData({account_name: payload.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(trybeActions.stakeTrybeRoutine.failure(serverResponse));
  }
}

function* unstakeTrybe({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'trybenetwork',
    method: 'unstake2',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(trybeActions.unStakeTrybeRoutine.success(serverResponse));
    const getBalances = yield getTrybeData({account_name: payload.id.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(trybeActions.unStakeTrybeRoutine.failure(serverResponse));
  }
}


function* claimTrybe({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'trybenetwork',
    method: 'claimdrop',
    data: payload,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(trybeActions.claimTrybeRoutine.success(serverResponse));
    const getBalances = yield getTrybeData({account_name: payload.id.account});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(trybeActions.claimTrybeRoutine.failure(serverResponse));
  }
}