import {put, takeLatest} from 'redux-saga/effects';
import * as boidActions from './boidActions';
import * as walletActions from '../../wallet/walletActions';
import * as walletClientApi from '../../wallet/walletClientApi';
import { getBoidData } from '../../accountData/fetchClientApi';
import {showProcessAlert, showSuccessAlert, showErrorAlert} from '../../alerts/showAlert';
import {successMessage, progressMessage} from '../../alerts/messages';

export const getBoidWatches = [
  getstakeTokenWatch,
  getunstakeTokenWatch,
  claimTokenWatch
];


export function* getstakeTokenWatch() {
  yield takeLatest(boidActions.stakeTokenRoutine.TRIGGER, stakeToken);
}

export function* getunstakeTokenWatch() {
  yield takeLatest(boidActions.unstakeTokenRoutine.TRIGGER, unstakeToken);
}

export function* claimTokenWatch() {
  yield takeLatest(boidActions.claimTokenRoutine.TRIGGER, claimToken);
}


function* stakeToken({payload}) {
  showProcessAlert(progressMessage); // Message
  console.log(payload.id.from)
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'boidcomtoken',
    method: 'stake',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert(successMessage, serverResponse.transaction_id);
    const getBalances = yield getBoidData({account_name: payload.id.from});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(boidActions.stakeTokenRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(boidActions.stakeTokenRoutine.failure(serverResponse));
  }
}

function* unstakeToken({payload}) {
  showProcessAlert(progressMessage); // Message
  console.log(payload);
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'boidcomtoken',
    method: 'unstake',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert(successMessage, serverResponse.transaction_id);
    const getBalances = yield getBoidData({account_name: payload.id.from});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(boidActions.unstakeTokenRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(boidActions.unstakeTokenRoutine.failure(serverResponse));
  }
}

function* claimToken({payload}) {
  showProcessAlert(progressMessage); // Message
  console.log(payload);
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'boidcomtoken',
    method: 'claim',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert(successMessage, serverResponse.transaction_id);
    yield put(boidActions.claimTokenRoutine.success(serverResponse));
    const getBalances = yield getBoidData({account_name: payload.id.stake_account});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(boidActions.claimTokenRoutine.failure(serverResponse));
  }
}