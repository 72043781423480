import formActionSaga from 'redux-form-saga';
import { fork, all } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';

import { getSeedWatches } from '../tokens/seed/seedSaga';
import { walletWatches } from '../wallet/walletSaga';
import { getTrybeWatches } from '../tokens/trybe/trybeSaga';
import { getPixeosWatches } from '../tokens/pixeos/pixeosSaga';
import { getBrmWatches } from '../tokens/brm/brmSaga';
import { getBoidWatches } from '../tokens/boid/boidSaga';
import { getEfxWatches } from '../tokens/efx/efxSaga';
import { getEmtWatches } from '../tokens/emt/emtSaga';
import { getHirevibesWatches } from '../tokens/hirevibes/hvtSaga';
import { getPeosWatches } from '../tokens/peos/peosSaga';
import { priceWatches } from '../prices/pricesSaga';

export default function* root() {
  const allWatchers = [
    ...getSeedWatches,
    ...walletWatches,
    ...getTrybeWatches,
    ...getPixeosWatches,
    ...getBrmWatches,
    ...getBoidWatches,
    ...getEfxWatches,
    ...getEmtWatches,
    ...getHirevibesWatches,
    ...priceWatches,
    ...getPeosWatches,
    routinePromiseWatcherSaga,
    formActionSaga
  ];

  yield all(allWatchers.map(saga => fork(saga)));
}
