import {put, takeLatest} from 'redux-saga/effects';
import * as priceActions from './pricesActions';
import { getTokenPricesApi } from './fetchTokenPricesApi';

export const priceWatches = [
  getTokenPricesWatch,
];

export function* getTokenPricesWatch() {
  yield takeLatest(priceActions.getTokenPricesRoutine.TRIGGER, getTokenPrices);
}

function* getTokenPrices() {
  const pricesResponse = yield getTokenPricesApi();
  if(pricesResponse) {
    yield put(priceActions.getTokenPricesRoutine.success(pricesResponse));
  } else {
    yield put(priceActions.getTokenPricesRoutine.failure());
  }
}