/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import * as walletActions from '../../../../wallet/walletActions';

class Lend extends Component {
  constructor() {
    super();
    this.state = {
      rexBuyAmount: ''
    };
  }

  onChangeInput = (event) => {
    this.setState({
      rexBuyAmount: event.target.value,
    });
  }

  handleChange = (e, { value }) => this.setState({ value })

  render() {
    const { acctName, buyRex, RexPrice, unstakeToRex, eosNet, eosCpu, rexFund } = this.props;
    const { rexBuyAmount, value } = this.state;
    const rexDefault = '0.0000';
    const lendRex = [
      { key: 'Use Liquid TLOS', text: 'Use Liquid TLOS', value: 1, },
      { key: 'Use Staked NET', text: 'Use Staked NET', value: 2, },
      { key: 'Use Staked CPU', text: 'Use Staked CPU', value: 3, },
    ];
    return (
      <div className="st-pannel-inner st-card__lend">
        <Dropdown
          onChange={this.handleChange}
          placeholder="Select EOS Payment"
          fluid
          selection
          className="st-card__lend-select"
          options={lendRex}
          value={value}
        />
        <div className="row">
          <div className="col col-md-6 text-center">
            <p className="st-card__bold ">EOS TO LEND</p>
            <input
              type="number"
              id="rexBuyAmount"
              min="0"
              placeholder={rexDefault}
              value={rexBuyAmount}
              className="st-input st-input--number"
              onChange={this.onChangeInput}
              autoComplete="off"
            />
            <p className="st-card__bold font-s">
              {value == 2 ? "AVAILABLE NET: " + eosNet : value == 3 ? "AVAILABLE CPU: " + eosCpu : 'AVAILABLE FUND: ' + rexFund}
            </p>
          </div>

          <div className="col col-md-6 text-center">
            <p className="st-card__bold">REX TO BUY</p>
            <p className="st-card__number st-card__number--purple">{rexBuyAmount ? parseFloat(rexBuyAmount / parseFloat(RexPrice.price).toFixed(8)).toFixed(4) : '0.0000'}</p>
          </div>
        </div>
        <button
          type="button"
          className="st-button st-button--purple st-button--large"
          disabled={!acctName}
          onClick={() => {
            value == 1 ? buyRex({
              acctName,
              data: {
                from: acctName,
                amount: parseFloat(rexBuyAmount).toFixed(4) + ' TLOS',
              }
            }) : value == 2 ? unstakeToRex({
              acctName,
              data: {
                owner: acctName,
                receiver: acctName,
                from_net: parseFloat(rexBuyAmount).toFixed(4) + ' TLOS',
                from_cpu: '0.0000 TLOS'
              }
            }) : value == 3 ? unstakeToRex({
              acctName,
              data: {
                owner: acctName,
                receiver: acctName,
                from_net: '0.0000 TLOS',
                from_cpu: parseFloat(rexBuyAmount).toFixed(4) + ' TLOS'
              }
            }) : buyRex({
              acctName,
              data: {
                from: acctName,
                amount: parseFloat(rexBuyAmount).toFixed(4) + ' TLOS',
              }
            });
          }}> BUY REX
        </button>
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const eosBal = state.data.getIn(['accountInfo', 'accountRows', 'core_liquid_balance'], 0);
    const rexBal = state.data.getIn(['accountInfo', 'rexRows', 'rows', 0, 'rex_balance'], 0);
    const rexFund = state.data.getIn(['accountInfo', 'rexRows', 'rexFunds', 'rows', 0, 'balance'], 0);

    const eosCpu = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'cpu_weight'], 0);
    const eosNet = state.data.getIn(['accountInfo', 'accountRows', 'self_delegated_bandwidth', 'net_weight'], 0);

    return {
      acctName,
      eosBal,
      rexBal,
      rexFund,
      eosCpu,
      eosNet
    };
  },
  dispatch => ({
    buyRex: id => dispatch(walletActions.lendRexRoutine({ id })),
    unstakeToRex: id => dispatch(walletActions.unstakeToRexRoutine({ id }))
  }),
)(Lend));