import axios from 'axios';

export function* getTokenPricesApi() {
  const getTokensPrices = {};
  //const key = 'hoab7mt9941lvk90'; //Newdex Key

  //============
  // Token Prices
  //============
  try {
    const getTokenPriceData = yield axios.get(
      process.env.REACT_APP_TOKEN_PRICE_URL, {
        headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` }
      });
    getTokensPrices.data = getTokenPriceData.data;
  } catch (e) {
    console.error('Error retrieving Token Prices', e);
  }
  return getTokensPrices;
}