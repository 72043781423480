import { put, takeLatest } from 'redux-saga/effects';
import * as walletActions from '../../wallet/walletActions';
import * as walletClientApi from "../../wallet/walletClientApi";
import { getEfxData } from '../../accountData/fetchClientApi';
import * as efxActions from './efxActions';
import { showErrorAlert, showProcessAlert, showSuccessAlert } from "../../alerts/showAlert";


export const getEfxWatches = [
  getEfxStakeWatch,
  getEfxUnstakeWatch,
  claimEfxWatch
];


export function* getEfxStakeWatch() {
  yield takeLatest(efxActions.getEfxStakeRoutine.TRIGGER, stakeEfx);
}
export function* getEfxUnstakeWatch() {
  yield takeLatest(efxActions.getEfxUnstakeRoutine.TRIGGER, unstakeEfx);
}
export function* claimEfxWatch() {
  yield takeLatest(efxActions.claimEfxRoutine.TRIGGER, claimEfx);
}

// Stake EFX Action
function* stakeEfx({ payload }) {
  const checkStake = [
    {
      new: payload.id.newStake,
    }
  ];
  //First Time Staker
  const newStakeAction = [
    {
      contract: 'efxstakepool',
      method: 'open',
      fields: [{
        owner: payload.id.from,
        ram_payer: payload.id.from
      }]
    },
    {
      contract: 'effecttokens',
      method: 'open',
      fields: [{
        owner: payload.id.from,
        symbol: '4,NFX',
        ram_payer: payload.id.from
      }]
    },
    {
      contract: 'effecttokens',
      method: 'transfer',
      fields: [{
        from: payload.id.from,
        to: 'efxstakepool',
        quantity: payload.id.quantity,
        memo: 'stake',
      }]
    }
  ];
  //Update Stake
  const updateStakeAction = [
    {
      contract: 'efxstakepool',
      method: 'claim',
      fields: [{
        owner: payload.id.from
      }]
    },
    {
      contract: 'effecttokens',
      method: 'transfer',
      fields: [{
        from: payload.id.from,
        to: 'efxstakepool',
        quantity: payload.id.quantity,
        memo: 'stake',
      }]
    }
  ];
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  if (checkStake[0].new) {
    const serverResponse = yield walletClientApi.multiTransaction({
      results: newStakeAction,
    });
    if (serverResponse.processed) {
      showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
      const getBalances = yield getEfxData({account_name: payload.id.from});
      if(getBalances) {
        yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
        yield put(efxActions.getEfxStakeRoutine.success(serverResponse));
      }
    } else {
      (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
      yield put(efxActions.getEfxStakeRoutine.failure(serverResponse));
    }
  } else {
    const serverResponse = yield walletClientApi.multiTransaction({
      results: updateStakeAction,
    });
    if (serverResponse.processed) {
      showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
      const getBalances = yield getEfxData({account_name: payload.id.from});
      if(getBalances) {
        yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
        yield put(efxActions.getEfxStakeRoutine.success(serverResponse));
      }
    } else {
      (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
      yield put(efxActions.getEfxStakeRoutine.failure(serverResponse));
    }
  }
}

function* unstakeEfx({ payload }) {
  const unStakeAction = [
    {
      contract: 'efxstakepool',
      method: 'claim',
      fields: [{
        owner: payload.id.owner
      }]
    },
    {
      contract: 'efxstakepool',
      method: 'unstake',
      fields: [{
        owner: payload.id.owner,
        quantity: payload.id.quantity,
      }]
    }
  ];
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload)
  const serverResponse = yield walletClientApi.multiTransaction({
    results: unStakeAction
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getEfxData({account_name: payload.id.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(efxActions.getEfxUnstakeRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(efxActions.getEfxUnstakeRoutine.failure(serverResponse));
  }
}
/*****************
    CLAIM EFX
*****************/
function* claimEfx({ payload }) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload)
  const serverResponse = yield walletClientApi.broadcastTransaction({
    contract: 'efxstakepool',
    method: 'claim',
    data: payload.id,
  });
  if (serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    const getBalances = yield getEfxData({account_name: payload.id.owner});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
      yield put(efxActions.claimEfxRoutine.success(serverResponse));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(efxActions.claimEfxRoutine.failure(serverResponse));
  }
}