// Error.js
import React from 'react';


const Error = () => (

  <div className="st-main-body-inner">
    <div className="row">
      <div className="col col-md-12 st-card-col">
        <div className="no-border">
          <h1 className="text-center text-bold-700" style={{fontSize: '10vh'}}>Oops!</h1>
          <h4 className="text-center">The page you were looking for was moved or removed. Return home and try again.</h4>
        </div>
      </div>
    </div>
  </div>
);

export default Error;
