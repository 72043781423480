/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Request extends Component {
  render() {
    return (
      <div className="st-main-body">
        <div className="st-main-body-inner">
          <div className="row">
            <div className="col col-12 st-card-col">
              <div className="st-card">
                <iframe title="test" src="https://docs.google.com/forms/d/e/1FAIpQLScPQiqZeSRQ8Cm-0_5bb843-pIfwcufLoW0NrdG5f8tnWlfQw/viewform?embedded=true" width="100%" height="1451" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter((Request));