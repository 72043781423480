/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { Image } from 'semantic-ui-react';

import BoidBody from './boidBody';
import ContentLoader from '../../components/loader';


class BoidData extends Component {
  render() {
    const { acctName, acctBal, allAcctStakes, TokenPrices } = this.props;
    const tokenDetails = {
      title: 'BOID',
      tagline: 'The Social Supercomputer',
      about: 'The Boid token (BOID) unlocks advanced functionality on the Boid platform. Users can stake their BOIDs to upgrade their account functionality. Staking BOIDS is required to create a Boid Team and issue team assets. BOIDs can also be staked for Proof-of-stake mining and voting. Staking BOIDs is required to operate a Boid masternode.',
      tokeSymbal: 'BOID',
      imageSrc: '../assets/logos/NewBoidLogo.svg',
      links: [
        //remove not in use
        { url: 'https://www.boid.com/', icon: '../assets/images/social/www.svg', name: 'website', class: 'social-link' },
        { url: 'https://t.me/Boidcom_official', icon: '../assets/images/social/telegram.svg', name: 'telegram', class: 'social-link' },
        { url: 'https://twitter.com/boidcom', icon: '../assets/images/social/twitter.svg', name: 'twitter', class: 'social-link' },
      ],
      get tokenBody() {
        return <BoidBody tokensymbal={this.tokeSymbal} />;
      }
    }

    const allStakes = allAcctStakes.toJS();
    let stakeTotal = 0;
    let selfStake = 0;

    allStakes.forEach((obj) => {
      if (obj.from == acctName) {
        selfStake += parseFloat(obj.quantity)
        stakeTotal += parseFloat(obj.quantity)
        console.log(selfStake)
      } else {
        stakeTotal += parseFloat(obj.quantity)
      }
    });

    const liquidBal = (parseFloat(parseFloat(acctBal).toFixed(4) - parseFloat(selfStake).toFixed(4))).toFixed(4) + ` BOID`;

    const socialLinks = items => items.map((item) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <a href={item.url} key={item.name} target="_blank" rel="noopener noreferrer">
          <Image src={item.icon} alt={item.name} className={item.class} />
        </a>
      );
    });

    return (
      <div className="st-main-body-inner">
        <div className="row">
          <div className="col col-md-4 st-card-col">
            <div className="st-card st-card__minheight">
              <h5 className="st-card__headline">{tokenDetails.tokeSymbal}: {tokenDetails.tagline}</h5>
              <div className="st-card__token-info">
                <Image src={tokenDetails.imageSrc} alt="karma" /> <span className="st-card__token-name">{tokenDetails.tokeSymbal}</span>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__price-nob">
                  <h5>Price</h5>
                  <p>${TokenPrices ? TokenPrices.price : '0.00'}</p>
                </div>
              </div>
              <div className="st-card__prices">
                <div className="divider-horizontal" />
                <div className="st-card__split-min">
                  <Image src="../../assets/images/min-arrow.svg" alt="min" />
                  <div>
                    <h5>MIN 24H</h5>
                    <p>${TokenPrices ? TokenPrices.low : '0.00'}</p>
                  </div>
                </div>
                <div className="st-card__split-max">
                  <Image src="../../assets/images/min-arrow.svg" alt="max" />
                  <div>
                    <h5>MAX 24H</h5>
                    <p>${TokenPrices ? TokenPrices.high : '0.00'}</p>
                  </div>
                </div>
              </div>
              <div className="st-card__description">
                {tokenDetails.about}
                <br />
              </div>
              <div className="st-card__socials">
                {socialLinks(tokenDetails.links)}
              </div>
            </div>
          </div>
          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">BALANCES</h5>
              <Image className="st-resources__icon" src="../assets/images/dropp-arrow-icon.svg" alt="min" />
              <h4 className="st-resources__title">Total Balance</h4>
              <p className="st-resources__balance">{acctBal ? acctBal : `0.0000 ${tokenDetails.tokeSymbal}`}</p>
              <ul className="st-resources__info list-reset">
                <li>
                  <h5>Liquid Balance</h5>
                  <p>{liquidBal ? liquidBal : `0.0000 ${tokenDetails.tokeSymbal}`}</p>
                </li>
                <li>
                  <h5>Self + Delegated Stake</h5>
                  <p>{stakeTotal ? `${stakeTotal.toFixed(4)} ${tokenDetails.tokeSymbal}` : `0.0000 ${tokenDetails.tokeSymbal}`}</p>
                </li>
              </ul>
              <ContentLoader size="massive" />
            </div>
          </div>
          <div className="col col-12 col-md-4 st-card-col">
            <div className="st-card">
              <h5 className="st-card__headline">Details</h5>
              <h4 className="st-resources__title mt-3">Staking</h4>
              <p>You can stake BOID to amplify the profitability of your generated Boid Power.</p>
              <div className="st-card__return">
                <div className="divider-horizontal" />
              </div>
              <h4 className="st-resources__title mt-3">Unlock advanced features</h4>
              <p>Users can stake (lock up) their BOIDs to gain access to advanced account functionality and platform bonuses.</p>
              <div className="st-card__return">
                <div className="divider-horizontal" />
              </div>
              <h4 className="st-resources__title mt-3">Supernodes</h4>
              <p>Stake your BOIDs as part of a Boid supernode to provide important infrastructure support to the network. Each supernode is a associated with a specific team, and receives income based on team performance.</p>
              <div className="st-card__return">
                <div className="divider-horizontal" />
              </div>
            </div>
          </div>
        </div>
        {tokenDetails.tokenBody}
      </div>
    );
  }
}

export default withRouter(connect(
  (state) => {
    const acctName = state.data.getIn(['identity', 'account_name']);
    const acctBal = state.data.getIn(['accountInfo', 'boidRows', 'rows', 0, 'balance'], 0);
    const allAcctStakes = state.data.getIn(['accountInfo', 'boidRows', 'staked', 'rows'], List());
    const acctStaked = state.data.getIn(['accountInfo', 'boidRows', 'staked', 'rows', 0, 'quantity'], 0);

    const refunding = state.data.getIn(['accountInfo', 'boidRows', 'delegation', 'rows', 0, 'quantity'], 0);

    return {
      acctName,
      acctBal,
      allAcctStakes,
      acctStaked,
      refunding

    };
  }
)(BoidData));