import React, { Component } from 'react';
import { Provider } from 'react-redux';
import configureStore from './app/redux/configureStore';
import MainBody from './app/components/main';
import './style/all.scss';


import 'semantic-ui-css/semantic.min.css';

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <div className="App">
          <MainBody />
        </div>
      </Provider>
    );
  }
}

export default App;