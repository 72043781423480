import {put, takeLatest} from 'redux-saga/effects';
import * as hirevibesActions from './hvtActions';
import * as walletActions from '../../wallet/walletActions';
import * as WalletClientApi from '../../wallet/walletClientApi';
import { getHvtData } from '../../accountData/fetchClientApi';
import {showProcessAlert, showSuccessAlert, showErrorAlert} from '../../alerts/showAlert';

export const getHirevibesWatches = [
  getHirevibesStakeWatch,
  getHirevibesUnStakeWatch
];

export function* getHirevibesStakeWatch() {
  yield takeLatest(hirevibesActions.stakeHvtRoutine.TRIGGER, stakeHirevibes);
}

export function* getHirevibesUnStakeWatch() {
  yield takeLatest(hirevibesActions.unStakeHvtRoutine.TRIGGER, unstakeHirevibes);
}


function* stakeHirevibes({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'hirevibeshvt',
    method: 'transfer',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(hirevibesActions.stakeHvtRoutine.success(serverResponse));
    const getBalances = yield getHvtData({account_name: payload.id.from});
    if(getBalances) {
      yield put(walletActions.getAccountBalancesRoutine.success(getBalances));
    }
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(hirevibesActions.stakeHvtRoutine.failure(serverResponse));
  }
}

function* unstakeHirevibes({payload}) {
  showProcessAlert('Transaction in progress, please stand by...'); // Message
  console.log(payload);
  const serverResponse = yield WalletClientApi.broadcastTransaction({
    contract: 'hvtstakingio',
    method: 'powerdown',
    data: payload.id,
  });
  if(serverResponse.processed) {
    showSuccessAlert('Transaction was Successful.', serverResponse.transaction_id);
    yield put(hirevibesActions.unStakeHvtRoutine.success(serverResponse));
  } else {
    (serverResponse.message ? showErrorAlert(serverResponse.message) : showErrorAlert(serverResponse));
    yield put(hirevibesActions.unStakeHvtRoutine.failure(serverResponse));
  }
}